import React from 'react';
import {
  copyFill,
  feedbackButton,
  iconContainer,
  thumbsUp,
  thumbsDown,
} from './Chatbot.module.css';

export const SparkleIcon = ({ color = '#3B2CE3', factor = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24 + (factor - 1) * 8}
    height={24 + (factor - 1) * 8}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.4079 3.33334L14.4079 10L21.0745 12L14.4079 14L12.4079 20.6667L10.4079 14L3.74121 12L10.4079 10L12.4079 3.33334Z"
      fill={color}
    />
  </svg>
);

export const FeedbackIcon = ({ type, selected = false, onClick }) => {
  switch (type) {
    case 'thumbsUp':
      return (
        <button onClick={onClick} className={feedbackButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className={selected ? thumbsUp : undefined}
          >
            <g clipPath="url(#clip0_338_5892)">
              <path
                opacity="0.3"
                d="M14 8.00002V6.66669H8L8.89333 3.10669L6 6.00002V12.6667H12L14 8.00002Z"
                fill="none"
              />
              <path
                d="M6.00008 14H12.0001C12.5534 14 13.0267 13.6666 13.2267 13.1866L15.2401 8.48663C15.3001 8.33329 15.3334 8.17329 15.3334 7.99996V6.66663C15.3334 5.93329 14.7334 5.33329 14.0001 5.33329H9.79341L10.4267 2.28663L10.4467 2.07329C10.4467 1.79996 10.3334 1.54663 10.1534 1.36663L9.44675 0.666626L5.05341 5.05996C4.81341 5.29996 4.66675 5.63329 4.66675 5.99996V12.6666C4.66675 13.4 5.26675 14 6.00008 14ZM6.00008 5.99996L8.89341 3.10663L8.00008 6.66663H14.0001V7.99996L12.0001 12.6666H6.00008V5.99996ZM0.666748 5.99996H3.33341V14H0.666748V5.99996Z"
                fill="#666679"
              />
            </g>
            <defs>
              <clipPath id="clip0_338_5892">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      );
    case 'thumbsDown':
      return (
        <button onClick={onClick} className={feedbackButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className={selected ? thumbsDown : undefined}
          >
            <g clipPath="url(#clip0_338_6436)">
              <path
                opacity="0.3"
                d="M2 7.99992V9.33325H7.84667L7.10667 12.8933L10 9.99992V3.33325H4L2 7.99992Z"
                fill="none"
              />
              <path
                d="M9.99996 2H3.99996C3.44663 2 2.97329 2.33333 2.77329 2.81333L0.759959 7.51333C0.699959 7.66667 0.666626 7.82667 0.666626 8V9.33333C0.666626 10.0667 1.26663 10.6667 1.99996 10.6667H6.20663L5.57329 13.7133L5.55329 13.9267C5.55329 14.2 5.66663 14.4533 5.84663 14.6333L6.55329 15.3333L10.94 10.94C11.1866 10.7 11.3333 10.3667 11.3333 10V3.33333C11.3333 2.6 10.7333 2 9.99996 2ZM9.99996 10L7.10663 12.8933L7.84663 9.33333H1.99996V8L3.99996 3.33333H9.99996V10ZM12.6666 2H15.3333V10H12.6666V2Z"
                fill="#666679"
              />
            </g>
            <defs>
              <clipPath id="clip0_338_6436">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      );
  }
};

export const SendMessageIcon = ({ loading }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <g clipPath="url(#clip0_308_5022)">
      <path
        d="M4.01 6.53L11.52 9.75L4 8.75L4.01 6.53ZM11.51 15.25L4 18.47V16.25L11.51 15.25ZM2.01 3.5L2 10.5L17 12.5L2 14.5L2.01 21.5L23 12.5L2.01 3.5Z"
        fill={loading ? '#B4CCFC' : '#2100D9'}
      />
    </g>
    <defs>
      <clipPath id="clip0_308_5022">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const ExpandIcon = ({ onClick, color = '#DEDFE6' }) => (
  <button onClick={onClick} className={iconContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M8.72729 7.54002L8.7273 7.54003C8.98117 7.79475 9.393 7.79477 9.64687 7.54002L12.8218 4.35426L12.7155 4.24837L12.8218 4.35426C12.8249 4.35108 12.8291 4.34939 12.8334 4.34939C12.8421 4.34939 12.85 4.35663 12.85 4.36662V6.09632C12.85 6.45576 13.1406 6.74799 13.5 6.74799C13.8595 6.74799 14.15 6.45576 14.15 6.09632V2.75169C14.15 2.39224 13.8595 2.1 13.5 2.1H10.1668C9.80738 2.1 9.51684 2.39224 9.51684 2.75169C9.51684 3.11114 9.80738 3.40338 10.1668 3.40338H11.8906C11.8949 3.40338 11.899 3.40508 11.9022 3.40825C11.909 3.41502 11.909 3.4262 11.9022 3.43298L8.72729 6.61876L8.83354 6.72464L8.72729 6.61876C8.4737 6.87322 8.4737 7.28556 8.72729 7.54002ZM7.27291 9.88124L7.16667 9.77536L7.27291 9.88124C7.52651 9.62678 7.52651 9.21444 7.27291 8.95997C7.01903 8.70523 6.6072 8.70523 6.35331 8.95997L3.17827 12.1458L3.28452 12.2516L3.17827 12.1458C3.17511 12.1489 3.17093 12.1506 3.16667 12.1506C3.15794 12.1506 3.15 12.1434 3.15 12.1334V10.4037C3.15 10.0443 2.85946 9.75201 2.5 9.75201C2.14054 9.75201 1.85 10.0443 1.85 10.4037V13.7483C1.85 14.1078 2.14054 14.4 2.5 14.4H5.83333C6.1928 14.4 6.48333 14.1078 6.48333 13.7483C6.48333 13.3889 6.1928 13.0966 5.83333 13.0966H4.10948C4.10522 13.0966 4.10103 13.0949 4.09787 13.0918C4.09112 13.085 4.09112 13.0738 4.09787 13.067L7.27291 9.88124Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  </button>
);
export const MinimizeIcon = ({ onClick, color = '#DEDFE6' }) => (
  <button onClick={onClick} className={iconContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.67236 5.21453C9.67236 5.25888 9.68998 5.30142 9.72134 5.33278C9.78665 5.39809 9.89254 5.39809 9.95785 5.33278L13.1437 2.14694C13.3396 1.95102 13.6573 1.95102 13.8532 2.14694C14.0491 2.34287 14.0491 2.66053 13.8532 2.85645L10.6674 6.04229C10.636 6.07365 10.6184 6.11619 10.6184 6.16054C10.6184 6.2529 10.6932 6.32777 10.7856 6.32777H12.5153C12.7924 6.32777 13.017 6.55239 13.017 6.82947C13.017 7.10655 12.7924 7.33117 12.5153 7.33117H9.17066C8.89358 7.33117 8.66896 7.10655 8.66896 6.82947V3.48481C8.66896 3.20773 8.89358 2.98311 9.17066 2.98311C9.44775 2.98311 9.67236 3.20773 9.67236 3.48481V5.21453ZM6.3277 10.7856C6.3277 10.7413 6.31008 10.6987 6.27872 10.6674C6.21341 10.602 6.10753 10.602 6.04222 10.6674L2.85638 13.8531C2.66045 14.049 2.3428 14.049 2.14687 13.8531C2.14687 13.8531 2.14688 13.8531 2.14687 13.8531C1.95096 13.6571 1.95095 13.3395 2.14687 13.1436L5.33269 9.95788C5.36405 9.92652 5.38167 9.88399 5.38167 9.83963C5.38167 9.74727 5.3068 9.6724 5.21444 9.6724H3.48472C3.20765 9.6724 2.98304 9.44779 2.98304 9.17072C2.98304 8.89365 3.20765 8.66904 3.48472 8.66904H6.8294C7.10648 8.66904 7.3311 8.89366 7.3311 9.17074L7.3311 12.5152C7.3311 12.7923 7.10648 13.0169 6.8294 13.0169C6.55232 13.0169 6.3277 12.7923 6.3277 12.5152V10.7856Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  </button>
);

export const HelpIcon = ({ onClick, color = '#DEDFE6' }) => (
  <button onClick={onClick} className={iconContainer}>
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
      <path
        d="M4.83334 12.0833H3.16667V10.4167H4.83334V12.0833ZM6.55834 5.62499L5.80834 6.39166C5.20834 6.99999 4.83334 7.49999 4.83334 8.74999H3.16667V8.33332C3.16667 7.41666 3.54167 6.58332 4.14167 5.97499L5.175 4.92499C5.48334 4.62499 5.66667 4.20832 5.66667 3.74999C5.66667 2.83332 4.91667 2.08332 4.00001 2.08332C3.08334 2.08332 2.33334 2.83332 2.33334 3.74999H0.666672C0.666672 1.90832 2.15834 0.416656 4.00001 0.416656C5.84167 0.416656 7.33334 1.90832 7.33334 3.74999C7.33334 4.48332 7.03334 5.14999 6.55834 5.62499Z"
        fill={color}
      />
    </svg>
  </button>
);

export const CopyIcon = ({ color = '#666679' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g clipPath="url(#clip0_706_8115)">
      <path
        className={copyFill}
        opacity="0.3"
        d="M9.33331 4.88086H5.33331V14.2142H12.6666V8.21419H9.33331V4.88086Z"
        fill={color}
      />
      <path
        d="M10.6666 0.880859H2.66665C1.93331 0.880859 1.33331 1.48086 1.33331 2.21419V11.5475H2.66665V2.21419H10.6666V0.880859ZM9.99998 3.54753H5.33331C4.59998 3.54753 4.00665 4.14753 4.00665 4.88086L3.99998 14.2142C3.99998 14.9475 4.59331 15.5475 5.32665 15.5475H12.6666C13.4 15.5475 14 14.9475 14 14.2142V7.54753L9.99998 3.54753ZM12.6666 14.2142H5.33331V4.88086H9.33331V8.21419H12.6666V14.2142Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_706_8115">
        <rect width="16" height="16" fill="white" transform="translate(0 0.213867)" />
      </clipPath>
    </defs>
  </svg>
);

import React from 'react';
import { observer } from 'mobx-react';
import { userStore } from '../../stores';
import { Text, Link } from '@mqd/volt-base';
import * as s from './SwaggerBanners.module.css';

const SwaggerBanner = ({ text, ctaAction, defaultBanner, testId = '' }) => {
  return (
    <div className={s.wrap} data-testid={testId}>
      <div className={s.cta} onClick={ctaAction ? ctaAction : null}>
        <div className={s.ctaText}>
          {ctaAction ? (
            <Link onClick={ctaAction} iconType="arrow-right" inline={true}>
              {text}
            </Link>
          ) : (
            text
          )}
        </div>
      </div>
      {defaultBanner}
    </div>
  );
};

const navigateToGetSandboxURL = () => {
  window.location.href = process.env.GATSBY_AMC_DEVELOPMENT_URL;
};

const creatingSandboxText = (
  <div>
    <Text type="h6" inline={true} color="#434355">
      Your sandbox is being created now.
    </Text>{' '}
    <Text type="body-sm" inline={true} className={s.subtext}>
      Once finished, you can use API widgets.
    </Text>
  </div>
);

const RenderSwaggerBanner = ({ defaultStateBanner = null }) => {
  if (!userStore.loggedIn) {
    // Sign in banner
    return (
      <SwaggerBanner
        testId="Signin Banner"
        ctaAction={userStore.logIn}
        text="Sign in to use API widgets"
        defaultBanner={defaultStateBanner}
      />
    );
  } else {
    try {
      const job_state = userStore.sandboxJobState;
      if (job_state === null) {
        // Create sandbox banner
        return (
          <SwaggerBanner
            ctaAction={navigateToGetSandboxURL}
            text="Create a sandbox to use API widgets"
            defaultBanner={defaultStateBanner}
          />
        );
      } else if (job_state && job_state !== 'completed') {
        // Creating sandbox banner
        return <SwaggerBanner text={creatingSandboxText} defaultBanner={defaultStateBanner} />;
      } else {
        return defaultStateBanner;
      }
    } catch {
      return defaultStateBanner;
    }
  }
};

export default observer(RenderSwaggerBanner);

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { lightboxStore } from '../../stores';
import * as s from './Lightbox.module.css';

class Lightbox extends Component {
  dataFromImg(img, src) {
    const imgRect = img.getBoundingClientRect();
    return {
      src: src || img.src,
      height: img.clientHeight,
      offsetTop: imgRect.top,
    };
  }

  dataFromEl(el) {
    const data = {
      src: '',
      height: '',
      offsetTop: '',
    };
    const img = el.querySelector('img[srcset]');
    if (img !== null) {
      const srcset = img.srcset.split(',');
      const imgRect = img.getBoundingClientRect();
      data.src = srcset.pop().split(' ')[0].trim();
      data.height = img.clientHeight;
      data.offsetTop = imgRect.top;
    }
    return data;
  }

  get data() {
    const { lightboxImage } = lightboxStore;
    if (lightboxImage.nodeName === 'IMG') {
      return this.dataFromImg(lightboxImage);
    } else {
      return this.dataFromEl(lightboxImage);
    }
  }

  get styles() {
    const big = lightboxStore.lightboxIsExpanded;
    const { src, height, offsetTop } = this.data;
    return {
      position: 'fixed',
      top: big ? '' : offsetTop,
      height: big ? '' : height,
      backgroundImage: `url(${src})`,
    };
  }

  render() {
    if (!lightboxStore.lightboxImage) {
      return null;
    }
    return (
      <div id="lightbox-cont" className={s.lightboxContainer} onClick={lightboxStore.closeLightbox}>
        <div className={s.lightboxImage} style={this.styles} />
      </div>
    );
  }
}

export default observer(Lightbox);

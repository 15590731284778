import React from 'react';
import { observer } from 'mobx-react';
import { eventsStore } from '../../stores';
import { BannerAlert as VBannerAlert, VSpacer } from '@mqd/volt-base';

const BannerAlert = ({ heading, type, size, ctaText, ctaAction, children }) => (
  <>
    <VBannerAlert
      heading={heading}
      type={type}
      size={eventsStore.mobileMode ? 'compact' : size}
      ctaText={ctaText}
      ctaAction={ctaAction}
    >
      {children}
    </VBannerAlert>
    <VSpacer factor={3} />
  </>
);

export default observer(BannerAlert);

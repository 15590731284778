import { eventsStore } from '../stores';

function scrollToAnchor() {
  // For scrolling to appropriate anchor on pageload if anchor is provided
  const hash =
    typeof window !== 'undefined' && window.location.hash && window.location.hash.slice(1); // Need to remove the '#' from the hash.
  if (!hash) return;

  if (eventsStore.pageLoaded) {
    scroll(hash);
  } else {
    eventsStore.onPageLoad(() => scroll(hash), true);
  }

  function findOffsetFromTop(elem) {
    let offset = 0;
    if (elem.offsetParent) {
      do {
        offset += elem.offsetTop;
      } while ((elem = elem.offsetParent));
    }
    return offset;
  }

  function scroll(hash) {
    setTimeout(() => {
      let elem = document.getElementById(hash);
      if (!elem) return;
      window.scrollTo({
        top: findOffsetFromTop(elem),
        behavior: 'smooth',
      });
    });
  }
}

export default { scrollToAnchor };

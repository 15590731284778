/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import '@mqd/volt-base/dist/normalize.css';
import '@mqd/volt-base/dist/style.css';
import '@mqd/volt-base/dist/typography.css';
import '@mqd/volt-codeblock/dist/style.css';
import './src/styles/hubspot.css';
import './src/styles/reference-tables.css';
import './src/styles/mobile.css';
import './src/styles/ie11.css';
import {
  hubspotHelper,
  ieHelper,
  layoutService,
  scrollHelper,
  searchHelper,
  utmHelper,
  cookiesHelper,
  utilService as util,
  gTagService,
} from './src/services';
import { userStore, eventsStore, pageDataStore } from './src/stores';

/* Actions when user lands on site */
hubspotHelper.stubJQuery();
ieHelper.flagIE();
utmHelper.trackUtmParams();
util.ensureDeviceId();

// Gatsby Browser lifecycle methods - listed IN ORDER of event timing

const onClientEntry = () => {
  // on each page change, set pageReady to false initially
  pageDataStore.onPageChange(() => eventsStore.pageReady(false));
};

const wrapPageElement = ({ element, props }) => {
  return layoutService.getLayout(element, props);
};

const onRouteUpdate = () => {
  const ready = document.readyState === 'complete';
  // if document is ready, call method, else, wait for it to be ready
  ready ? setPageReady() : window.addEventListener('load', setPageReady);

  // use alt title if there is no pageInfo
  const paths = location.pathname.replace(/^\/|\/$/g, '').split('/');
  // capitalize first letter
  const altTitle =
    paths[paths.length - 1].charAt(0).toUpperCase() + paths[paths.length - 1].slice(1);

  setTimeout(async () => {
    // site cookies
    cookiesHelper.init();
    // check user log in status
    await userStore.checkUserLogin();
    util.segmentTrackPage();
  }, 500);

  searchHelper.attachSearch();

  function setPageReady() {
    eventsStore.pageReady(true);
    window.removeEventListener('load', setPageReady);
  }
};

const onInitialClientRender = () => {
  eventsStore.listenForBreakpoint();
  // scroll to anchor on first load (if exists);
  scrollHelper.scrollToAnchor();
};

export { onRouteUpdate, onInitialClientRender, wrapPageElement, onClientEntry };

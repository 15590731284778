import React, { PureComponent } from 'react';
import { Admonition, Text, HSpacer, Link, VSpacer } from '@mqd/volt-base';
import { utilService, feedbackService } from '../../services';
import { globalModalStore } from '../../stores';
import PropTypes from 'prop-types';
import * as s from './FeedbackWidget.module.css';
import { desktop, mobile } from '../../styles/Base.module.css';

const status = {
  success: 'success',
  default: 'default',
};

export default class FeedbackWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: status.default,
      pending: false,
    };
  }

  submitFeedback(feedback) {
    const { feedbackId, type } = this.props;
    if (feedback === 'yes') {
      this.setState({ pending: true }, () => {
        feedbackService.submit(
          {
            feedbackId: feedbackId,
            feedback: null,
            helpfulBool: true,
            type: type,
          },
          // success callback
          () => {
            this.setState({
              pending: false,
              step: status.success,
            });
            utilService.segmentTrackEvent('Feedback Submitted', {
              element_id: feedbackId,
              type: 'helpful',
              helpful: true,
              element_id: feedbackId,
              element_type: type,
            });
          },
          // fail callback
          () =>
            this.setState({
              pending: false,
              step: status.default,
            })
        );
      });
    } else {
      // user selected 'no', so we ask them for feedback
      globalModalStore.setModal('feedback', { feedbackId, type, helpfulBool: false });
    }
  }

  render() {
    const { step, pending } = this.state;

    return (
      <>
        <span className={desktop}>
          <div
            className={s.wrap}
            style={{
              height: 'fit-content',
              display: step === status.success && !pending ? 'block' : 'flex',
            }}
          >
            {step === status.default && (
              <>
                <Text type="body-sm">Is this helpful?</Text>
                <HSpacer factor={1} />
                <Link onClick={() => this.submitFeedback('yes')} disabled={pending}>
                  Yes
                </Link>
                <HSpacer factor={1} />
                <Link onClick={() => this.submitFeedback('no')} disabled={pending}>
                  No
                </Link>
              </>
            )}

            {step === status.success && !pending && (
              <div className={s.admonition}>
                <Admonition heading="Thank you for your feedback!" type="tip">
                  <Text inline={true} className={s.successText}>
                    For additional assistance, reach out to the&nbsp;
                    <Link href="https://community.marqeta.com" external={true}>
                      Marqeta Developer Community
                    </Link>
                  </Text>
                </Admonition>
              </div>
            )}
          </div>
        </span>
        <span className={mobile}>
          <VSpacer factor={2} />
        </span>
      </>
    );
  }
}

FeedbackWidget.propTypes = {
  feedbackId: PropTypes.string,
  type: PropTypes.string,
};

FeedbackWidget.defaultProps = {
  feedbackId: '',
  type: 'unknown',
};

import { action, decorate, observable, configure } from 'mobx';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class LightboxStore {
  // observables
  lightboxImage = null;
  lightboxIsExpanded = false;

  setLightboxImage(image) {
    this.lightboxImage = image;
    if (image) {
      setTimeout(this.openLightbox, 100);
    }
  }

  clearLightboxImage() {
    this.setLightboxImage(null);
  }

  openLightbox() {
    this.lightboxIsExpanded = true;
  }

  closeLightbox() {
    this.lightboxIsExpanded = false;
    setTimeout(this.clearLightboxImage, 300);
  }
}

decorate(LightboxStore, {
  // values
  lightboxImage: observable,
  lightboxIsExpanded: observable,

  //actions
  setLightboxImage: action.bound,
  clearLightboxImage: action.bound,
  openLightbox: action.bound,
  closeLightbox: action.bound,

  // no computed
});

const lightboxStore = new LightboxStore();
export default lightboxStore;

import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'gatsby';
import { Text } from '@mqd/volt-base';
import { pageDataStore } from '../../stores';
import * as s from './SidenavList.module.css';

const SidenavList = ({ hasSidebar, links }) => {
  const activePage = pageDataStore.pagePath;
  const navMarkerRef = useRef();

  // Move marker on page change
  useEffect(() => {
    var activeLink = hasSidebar && navMarkerRef.current.parentElement.querySelector(`.${s.active}`);
    if (activeLink) {
      moveMarkerToActiveElement(activeLink);
    } else {
      hideMarker();
    }
  }, [activePage]);

  function moveMarkerToActiveElement(activeElement) {
    navMarkerRef.current.style.transform = `translateY(${activeElement.offsetTop}px) scaleY(${
      activeElement.offsetHeight / 24
    })`;
    navMarkerRef.current.style.display = 'block';
  }

  function hideMarker() {
    navMarkerRef.current.style.display = 'none';
  }

  const containerClassName = hasSidebar ? s.sidebarContainer : '';
  const activeClass = (urlPath) => (activePage === urlPath ? s.active : '');
  const sidenavList = (
    <ul className={`${s.list} ${containerClassName}`} data-testid="sidenav-list-container">
      <div className={s.navMarker} ref={navMarkerRef} />
      {links.map(({ urlPath, title }, i) => (
        <li key={i} className={s.listItem}>
          <Link to={urlPath}>
            <Text type="body-sm" testId="sidenav-link-text">
              <span className={`${s.listItemLink} ${activeClass(urlPath)}`}>{title}</span>
            </Text>
          </Link>
        </li>
      ))}
    </ul>
  );
  return sidenavList;
};

export default observer(SidenavList);

import { useState, useEffect } from 'react';

/**
 * Fires when the scroll position of a DOM element has changed
 * @param {DOMElement} elem - element to observe
 * @returns scroll position of element and boolean value if the element is currently scrolling
 */
export const useScrollPosition = (elem) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  let timeout;
  useEffect(() => {
    if (elem) {
      const updatePosition = () => {
        setScrollPosition(elem.scrollTop);
        setIsScrolling(true);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          setIsScrolling(false);
        }, 500);
      };
      elem.addEventListener('scroll', updatePosition);
      updatePosition();
      return () => elem.removeEventListener('scroll', updatePosition);
    }
  }, [elem]);

  return [isScrolling, scrollPosition];
};

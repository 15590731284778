import React from 'react';
import Helmet from 'react-helmet';
import {
  Footer,
  Header,
  GlobalToast,
  GlobalModal,
  GlobalAlert,
} from '../../components';
import '../default/layout.css';
import './layout.css';
import '../../styles/cludo.css';

const SearchLayout = ({ children }) => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://customer.cludo.com/css/templates/v1.1/essentials/cludo-search.min.css"
      />
      <script
        src="https://customer.cludo.com/scripts/bundles/search-script.min.js"
        type="text/javascript"
      />
    </Helmet>
    <div className="layout-wrap">
      <Header />
      {children}
      <Footer />
    </div>
    <GlobalToast />
    <GlobalModal />
    <GlobalAlert.footer />
  </>
);

export default React.memo(SearchLayout);

import { htmlProcessingService } from '.';
const { getAdocFullPath } = require('../../lib');

class AsciidocNode {
  constructor(node) {
    this.node = node;
    this.processedHtmlObj = htmlProcessingService.process(node.html);
  }

  get data() {
    const data = this.node.document;
    data.urlPath = getAdocFullPath(data.urlCollection, data.urlTitle);

    data.tags = data.tags || '';
    if (typeof data.tags === 'string') {
      data.tags = data.tags
        .split(',')
        .map((s) => s.trim())
        .filter((s) => s);
    }
    data.h2s = this.processedHtmlObj.level2Headings;
    return data;
  }

  get html() {
    return this.processedHtmlObj.html;
  }
}

export default AsciidocNode;

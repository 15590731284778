import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'gatsby';
import { Link as VoltLink, Logo, Button, HSpacer, VSpacer, Icon, Text } from '@mqd/volt-base';
import { SearchForm, Chiclet, Chatbot, GlobalAlert, MobileNav, TopNav } from '..';
import { eventsStore, userStore, pageDataStore, globalAlertStore } from '../../stores';
import * as s from './Header.module.css';
import { mobile, desktop } from '../../styles/Base.module.css';
const { getAdocFullPath } = require('../../../lib');

const dashboardUrl = process.env.GATSBY_AMC_BASE_URL;
const communityUrl = 'https://community.marqeta.com';
const salesUrl = 'https://www.marqeta.com/contact-us';

const Header = () => {
  return (
    <>
      <header className={s.header}>
        <GlobalAlert.header />
        <div className={s.topBar}>
          <HeaderLogo />
          {renderRightContent()}
        </div>
        {renderNavBar()}
      </header>
      {/* Used to offset all other elements from the fixed header. This must be equal to the total height of the header! */}
      <VSpacer
        factor={globalAlertStore.headerAlertPresent ? 20 : 15}
        transition={globalAlertStore.headerAlertPresent}
      />
    </>
  );
};

const HeaderLogo = () => {
  return (
    <div className={s.leftContent}>
      <Link to="/developer-guides" style={{ display: 'flex', alignItems: 'center' }}>
        <span className={mobile}>
          <Logo type="marq dark" width={36} />{' '}
        </span>
        <span className={desktop}>
          <Logo type="full dark" width={140} />{' '}
        </span>
        <HSpacer />
        <Chiclet text="DOCS" testId="header-chiclet" />
      </Link>
    </div>
  );
};

function renderRightContent() {
  return (
    <div
      className={s.rightContent}
      style={{
        transition: eventsStore.mobileMode ? 'none' : '',
      }}
    >
      <div className={s.headerOption}>
        <span className={s.searchWrap}>
          <SearchForm id="header-search-form" width="100%" testId="header-search-form" />
        </span>
      </div>
      {renderSupportDropdown()}
      {renderChatbotButton()}
      {renderUserLinks()}
    </div>
  );
}

function renderUserLinks() {
  if (userStore.loggedIn) {
    return (
      <div className={`${s.userLinks} ${desktop}`}>
        <VoltLink href={dashboardUrl} iconType="arrow-right" testId="logged-in-dashoard-link">
          Dashboard
        </VoltLink>
      </div>
    );
  } else {
    return (
      <div className={`${s.headerOption} ${desktop}`}>
        <Button type="primary" onClick={userStore.logIn} testId="header-sign-in-button">
          Sign in
        </Button>
      </div>
    );
  }
}

function renderChatbotButton() {
  // Will throw errors in SSR otherwise
  if (typeof window !== 'undefined') {
    return (
      <div className={`${s.headerOption} ${desktop}`}>
        <div data-testid="chatbot-icon">
          <Chatbot />
        </div>
      </div>
    );
  }
  return null;
}

function renderSupportDropdown() {
  return (
    <div className={`${s.headerOption} ${desktop}`}>
      <div className={`${s.supportDropdown}`} data-testid="support-icon">
        <Icon
          type="help-24"
          mod="default"
          factor={1}
          noHoverEffects={false}
          hasTooltip={true}
          onClick={() => {}}
        />
        <div
          className={s.supportDropdownContainer}
          style={{ left: userStore.loggedIn ? '-116px' : '-132px' }}
          data-testid="support-dropdown-container"
        >
          <Text type="label">Developer resources</Text>
          <SupportDropdownLink title="Community" link={communityUrl}>
            Collaborate on your project with other Marqeta developers
          </SupportDropdownLink>
          <SupportDropdownLink title="Sales" link={salesUrl}>
            Speak with an expert to learn more about Marqeta
          </SupportDropdownLink>
        </div>
      </div>
    </div>
  );
}
const SupportDropdownLink = ({ link, title, children }) => {
  return (
    <div className={s.supportDropdownLink}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Text className={s.supportDropdownLinkTitle} type="h6">
          {title}
        </Text>
        <Text type="body-sm">{children}</Text>
      </a>
    </div>
  );
};

function renderNavBar() {
  return (
    <div className={s.navBar} data-testid="header-nav-bar">
      <span className={mobile}>
        <MobileNav links={getMobileNavs()} testId="main-nav-mobile" />
      </span>
      <span className={desktop}>
        <TopNav links={getDesktopNavs()} testId="main-nav-desktop" />
      </span>
    </div>
  );
}

function getNavs() {
  // Using a map because a map keeps the order, while a plain object does not
  const navs = pageDataStore.siteMapInfo;
  const navMap = new Map();
  // Lumping collections together under common superCollections
  navs.forEach((collection) => {
    const superCollection = navMap.get(collection.collectionType);
    const collectionInfo = {
      urlPath: collection.urlPath,
      title: collection.title,
      ...(collection.specName && { apiExplorer: `/${collection.urlPath}-explorer` }),
      description: collection.description,
    };
    if (!superCollection) {
      navMap.set(collection.collectionType, [collectionInfo]);
    } else {
      superCollection.push(collectionInfo);
    }
  });
  return navMap;
}

function getMobileNavs() {
  const navMap = getNavs();
  const navsList = [];
  const currentCollection = pageDataStore.pageInfo.urlCollection;
  navMap.forEach((collections, superCollection) => {
    // If there is only one collection
    if (collections.length === 1) {
      const firstPagePath = getAdocFullPath(
        collections[0].urlPath,
        pageDataStore.getFirstPath(collections[0].urlPath)
      );
      navsList.push({
        content: <Link to={firstPagePath}>{collections[0].title}</Link>,
        active:
          currentCollection === collections[0].urlPath ||
          currentCollection === `${collections[0].urlPath}-explorer`,
      });
    }
    // If there is more than one collection
    else {
      console.error('Super Collections are not currently supported');
    }
  });
  return navsList;
}

function getDesktopNavs() {
  const navMap = getNavs();
  const navsList = [];
  const currentCollection = pageDataStore.pageInfo.urlCollection;
  navMap.forEach((collections, superCollection) => {
    // If there is only one collection
    if (collections.length === 1) {
      const firstPagePath = getAdocFullPath(
        collections[0].urlPath,
        pageDataStore.getFirstPath(collections[0].urlPath)
      );
      const apiExplorerPage = collections[0].apiExplorer;
      navsList.push({
        title: collections[0].title,
        contentLink: firstPagePath,
        ...(apiExplorerPage && { apiExplorer: apiExplorerPage }),
        active:
          currentCollection === collections[0].urlPath ||
          currentCollection === `${collections[0].urlPath}-explorer`,
        description: collections[0].description,
      });
    }
    // If there is more than one collection
    else {
      console.error('Super Collections are not currently supported');
    }
  });
  return navsList;
}
export default observer(Header);

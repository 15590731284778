// extracted by mini-css-extract-plugin
export var active = "TopNav-module--active--7fdd5";
export var content = "TopNav-module--content--e5fe9";
export var dropdown = "TopNav-module--dropdown--3ed50";
export var dropdownContainer = "TopNav-module--dropdownContainer--eece8";
export var dropdownLinkIcon = "TopNav-module--dropdownLinkIcon--53412";
export var dropdownLinkSection = "TopNav-module--dropdownLinkSection--53812";
export var dropdownLinkText = "TopNav-module--dropdownLinkText--3be5e";
export var dropdownLinkTitle = "TopNav-module--dropdownLinkTitle--94122";
export var dropdownTitle = "TopNav-module--dropdownTitle--5444b";
export var link = "TopNav-module--link--919bd";
export var wrap = "TopNav-module--wrap--d3e3d";
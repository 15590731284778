import {
  action,
  decorate,
  observable,
  computed,
  configure,
  observe,
} from 'mobx';

// store for misc site wide events

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class EventsStore {
  // observables
  pageLoaded = false;
  mobileMode = false;

  // static variables
  mobileBreakPoint = 1024;

  // will run callback when if page is loaded, or once it loads
  onPageLoad(callback, onlyOnceBool) {
    if (this.pageLoaded) {
      callback();
    } else {
      // if page isn't ready, then listen for it to be ready
      const dispose = observe(this, 'pageLoaded', change => {
        const pageLoaded = change.newValue;
        if (pageLoaded) {
          // if bool is true, then dispose the event
          onlyOnceBool && dispose();
          callback();
        }
      });
    }
  }

  // sets page readiness
  pageReady(status) {
    this.pageLoaded = status;
  }

  // checks to see if the mobile breakpoint should be triggered
  listenForBreakpoint() {
    const mediaQuery = window.matchMedia(
      `(max-width: ${this.mobileBreakPoint}px)`
    );
    mediaQuery.addListener(this.checkMobileBreakPoint);
    this.checkMobileBreakPoint(mediaQuery);
  }

  checkMobileBreakPoint(mq) {
    this.mobileMode = mq.matches;
  }
}

decorate(EventsStore, {
  // values
  pageLoaded: observable,
  mobileMode: observable,

  //actions
  pageReady: action.bound,
  onPageLoad: action.bound,
  listenForResize: action.bound,
  checkMobileBreakPoint: action.bound,

  // computed
});

const eventsStore = new EventsStore();
export default eventsStore;

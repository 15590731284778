import React, { useState } from 'react';
import { Button, Colors, HSpacer, Icon, Input, Text, VSpacer } from '@mqd/volt-base';
import { submitMarketoForm } from './newsletterWidgetFunctions.js';
import useMarketo from './hooks/useMarketo';
import * as s from './NewsletterWidget.module.css';

const FooterNewsletterWidget = () => {
  const [pending, setPending] = useState(false);
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const formId = 1130;
  useMarketo({ formId: formId });

  const handleSubmit = () => {
    if (!input) return;
    setDisplaySuccessMessage(false);
    setErrorMessage('');
    setPending(true);

    const formInput = {
      Email: input,
      subscriptionDeveloperNewsletterMonthly: 'yes',
    };

    submitMarketoForm(formId, formInput)
      .then(() => {
        setDisplaySuccessMessage(true);
      })
      .catch(() => {
        setErrorMessage('Enter a valid email address.');
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={s.footerContainer} data-testid="footer-newsletter-widget">
      <VSpacer factor={1} />
      <Icon type="envelope-active" noHoverEffects factor={4} />
      <VSpacer factor={0.5} />
      <Text type="h3">Subscribe to our developer newsletter</Text>
      <VSpacer factor={1} />
      <div className={s.form}>
        <Input
          width={416}
          placeholder="Enter email address"
          disabled={pending}
          value={input}
          error={errorMessage}
          preventErrorPushdown={true}
          onChange={(e) => setInput(e.target.value)}
        />
        <Button
          type="primary"
          disabled={pending}
          style={{ borderRadius: '0 4px 4px 0', marginLeft: '-3px' }}
          onClick={handleSubmit}
        >
          Subscribe
        </Button>
      </div>
      {displaySuccessMessage && <SuccessMessage />}
      <VSpacer factor={5} />
    </div>
  );
};

const SuccessMessage = () => (
  <div className={`${s.message}`} data-testid="newsletter-success-message">
    <Icon type="confirmed" overrideColor={Colors.statusSuccessColor} noHoverEffects factor={0} />
    <HSpacer factor={0.5} />
    <Text type="footnote">Thank you. You will receive our next newsletter.</Text>
  </div>
);

export default FooterNewsletterWidget;

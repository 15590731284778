import React, { useState, useEffect } from 'react';
import { retrieveEndpointInfo } from './apiWidgetFunctions';
import EndpointInfo from './endpoint_info/EndpointInfo';
import { Icon, VSpacer } from '@mqd/volt-base';
import * as s from './ApiWidget.module.css';

const ApiWidget = ({ testId, endpoint, method, specName }) => {
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [endpointInfo, setEndpointInfo] = useState(null);

  useEffect(() => {
    retrieveEndpointInfo({ endpoint, method, specName }).then(setEndpointInfo).catch(console.error);
  }, []);
  // componentDidMount

  return (
    <>
      <div
        data-testid={testId}
        className={`${s.wrap} ${hovered ? s.hovered : ''} ${expanded ? s.expanded : ''}`}
        style={{ height: expanded ? '' : 50 }}
      >
        <div
          className={s.widgetBar}
          onClick={() => setExpanded(!expanded)}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div className={s.coloredBar}>
            <div className={s.methodName}>{method.toUpperCase()}</div>
            {endpointInfo && <EndpointSummary endpoint={endpoint} summary={endpointInfo.summary} />}
          </div>
          <div className={s.expandArrow}>
            <Icon
              type="caret-down"
              mod="default"
              factor={1}
              noHoverEffects={true}
              hasTooltip={false}
            />
          </div>
        </div>
        {endpointInfo && (
          <EndpointInfo
            info={endpointInfo}
            endpoint={endpoint}
            method={method}
            visible={expanded}
          />
        )}
      </div>
      <VSpacer factor={2} />
    </>
  );
};

const EndpointSummary = (props) => {
  const { endpoint, summary } = props;

  return (
    <div className={s.endpointWrapper}>
      <div className={s.endpoint}>{endpoint}</div>
      <div className={s.endpointSummary}>{summary}</div>
    </div>
  );
};

export default React.memo(ApiWidget);

import React from 'react';
import * as s from './ReleaseNotesWidget.module.css';
import {
  activeLink,
  landingCellWrap,
  landingSectionContainer,
  landingSubtitle,
} from '../../styles/Base.module.css';
import { Link } from 'gatsby';
import { Text, HSpacer, Icon } from '@mqd/volt-base';
import { utilService } from '../../services';
import { LandingPageLink, NewsletterWidget } from '../../components';

function ReleaseNotesWidget({ releaseNotes }) {
  const releaseNotesArray = utilService.jsonToFormattedArray(releaseNotes);

  function TimelineNode({ children }) {
    return (
      <div className={s.nodeContainer}>
        <div className={s.nodeLine}>
          <div className={s.nodePoint} />
          <div className={s.nodeConnectorWrapper}>
            <div className={s.nodeConnector} />
          </div>
        </div>
        <div className={s.childrenContainer}>{children}</div>
      </div>
    );
  }

  function releaseNotesSection({ header, description, date, link }) {
    return (
      <div data-testid="release-note-cell" className={s.releaseNoteCell}>
        <TimelineNode>
          <Link to={link} className={`${activeLink} ${s.numberedListLink}`}>
            <Text className={s.linkText} type="h6" inline={true}>
              {header}
            </Text>
            <HSpacer factor={0.5} inline={true} />
            <Icon type="arrow-right" mod="default" factor={1} noHoverEffects={true} />
          </Link>
          <Text type="body-sm" inline={false} className={s.descriptionText}>
            {description}
          </Text>
          <Text type="footnote" inline={false} className={s.dateText}>
            {date}
          </Text>
        </TimelineNode>
      </div>
    );
  }

  return (
    <div className={s.flexContainer}>
      <div className={landingSectionContainer} data-testid="release-notes-section">
        <Text type="h2" className={landingSubtitle}>
          Latest release notes
        </Text>
        <div className={landingCellWrap}>
          {releaseNotesArray.map((section) => releaseNotesSection(section))}
          <LandingPageLink link="/developer-guides/release-notes" text="View all release notes" />
        </div>
      </div>
      <NewsletterWidget />
    </div>
  );
}

export default ReleaseNotesWidget;

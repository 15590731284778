import { useStaticQuery, graphql } from 'gatsby';

export const useImages = () => {
  const { allImages } = useStaticQuery(
    graphql`
      query {
        allImages: allFile(filter: { extension: { in: ["png", "jpg", "svg"] } }) {
          edges {
            node {
              publicURL
              extension
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  );
  return allImages;
};

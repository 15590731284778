/* This hook injects the Marketo form script onto the page.
From here we can continue to use our own components and submit the form using the MktoForms2 API. */

import { useState, useEffect } from 'react';

const useMarketo = ({ formId, callback = () => {} }) => {
  const [scriptAdded, setScriptAdded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const MUNCHKIN_ID = '270-LEK-671';

  useEffect(() => {
    if (scriptAdded) {
      if (!formLoaded) {
        MktoForms2.loadForm(`//${MUNCHKIN_ID}.mktoweb.com`, MUNCHKIN_ID, formId, callback);
        setFormLoaded(true);
      }
    } else {
      if (window.MktoForms2) {
        setScriptAdded(true);
      } else {
        const script = document.createElement('script');
        script.defer = true;
        script.onload = () => (window?.MktoForms2 ? setScriptAdded(true) : null);
        script.src = `//${MUNCHKIN_ID}.mktoweb.com/js/forms2/js/forms2.min.js`;
        document.head.appendChild(script);
      }
    }
  }, [scriptAdded]);
};

export default useMarketo;

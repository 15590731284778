import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as s from './Footer.module.css';
import { Text, Link } from '@mqd/volt-base';
import { path, pipe, filter, propEq } from 'ramda';

const Footer = ({ footerLinks }) => (
  <div className={s.container}>
    <Text type="body-sm" inline={true}>
      © {new Date().getFullYear()} Marqeta, Inc.
    </Text>

    {footerLinks.map(({ title, url, external }) => {
      return (
        <React.Fragment key={title + url}>
          <span className={s.divider}> • </span>
          <Link href={process.env.GATSBY_MARQETA_URL + url} className={s.link} newTab={false}>
            {title}
          </Link>
        </React.Fragment>
      );
    })}
  </div>
);

export default () => (
  <StaticQuery
    query={graphql`
      query footerLinks {
        footerLinks {
          internal {
            content
          }
        }
      }
    `}
    render={(data) => {
      const footerLinks = pipe(
        () => data,
        path(['footerLinks', 'internal', 'content']),
        JSON.parse,
        filter(propEq('title', 'Legal')),
        path([0, 'links'])
      )();
      return <Footer footerLinks={footerLinks} />;
    }}
  />
);

import React from 'react';
import * as s from './KeyConceptWidget.module.css';
import {
  activeLink,
  desktop,
  mobile,
  landingLinkContainer,
  landingLinkText,
  landingSectionContainer,
} from '../../styles/Base.module.css';
import { Link } from 'gatsby';
import { utilService } from '../../services';
import { Col, Row, Text } from '@mqd/volt-base';

// import Key Concept Icons
const iconSVGs = require.context('./icons', true, /\.svg$/);
const keyConceptIcons = iconSVGs.keys().reduce((images, path) => {
  const key = path.substring(path.lastIndexOf('/') + 1);
  images[key] = iconSVGs(path).default;
  return images;
}, {});

function KeyConceptWidget({ keyConcepts }) {
  const keyConceptsArray = utilService.jsonToFormattedArray(keyConcepts);
  const COLUMNS_IN_ROWS = 3;

  function keyConceptCell(icon, alt = '', header, description) {
    return (
      <>
        {icon && (
          <img
            className={s.icon}
            alt={alt}
            src={keyConceptIcons[icon]}
            data-testid="key-concept-icon"
          />
        )}
        <Text className={`${activeLink} ${landingLinkText}`} type="h6" inline={false}>
          {header}
        </Text>
        <Text type="body-sm">{description}</Text>
      </>
    );
  }

  function createKeyConceptRow(row, i) {
    const span = Math.floor(12 / COLUMNS_IN_ROWS);
    return (
      <Row key={i}>
        {row.map(({ icon, alt, header, link, description }, idx) => (
          <Link key={idx} to={link} className={`${landingLinkContainer} ${s.keyConceptCell}`}>
            <Col span={span} className={s.container}>
              {keyConceptCell(icon, alt, header, description)}
            </Col>
          </Link>
        ))}
      </Row>
    );
  }

  function createKeyConceptsForDesktop() {
    let section = [];
    const NUMBER_OF_ROWS = Math.ceil(keyConceptsArray.length / COLUMNS_IN_ROWS);
    for (let i = 0; i < NUMBER_OF_ROWS; i++) {
      const row = keyConceptsArray.slice(
        i * COLUMNS_IN_ROWS,
        i * COLUMNS_IN_ROWS + COLUMNS_IN_ROWS
      );
      section.push(createKeyConceptRow(row, i));
    }
    return section;
  }

  function createKeyConceptsForMobile() {
    return (
      <>
        {keyConceptsArray.map(({ icon, alt, header, link, description }, idx) => (
          <Link key={idx} to={link} className={landingLinkContainer}>
            <Col span={12} className={s.container}>
              {keyConceptCell(icon, alt, header, description)}
            </Col>
          </Link>
        ))}
      </>
    );
  }

  return (
    <div
      className={`${landingSectionContainer} ${s.keyConceptsContainer}`}
      data-testid="key-concepts-section"
    >
      <div className={desktop}>{createKeyConceptsForDesktop()}</div>
      <div className={mobile}>{createKeyConceptsForMobile()}</div>
    </div>
  );
}

export default KeyConceptWidget;

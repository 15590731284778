import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import ogImage from '../../images/marqeta-docs-og.png';

class SEO extends PureComponent {
  render() {
    const { desc, url, lang, title, redirectUrl, status } = this.props;

    const baseSplit = process.env.GATSBY_DOCS_URL.split('//');
    const baseProtocol = baseSplit[0];
    const baseDomain = baseSplit[1].split('/')[0];
    const ogImageUrl = `${baseProtocol}//${baseDomain}${ogImage}`;

    let branch = process.env.GATSBY_BRANCH;
    branch = branch ? '/' + branch : '';

    let meta = [
      {
        name: 'robots',
        content: status == 'hidden' ? 'noindex' : 'all',
      },
      {
        property: 'og:site_name',
        content: 'Marqeta',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:locale',
        content: 'en_US',
      },
      {
        property: 'og:url',
        content: url,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:image',
        content: ogImageUrl,
      },
      {
        property: 'twitter:card',
        content: 'summary',
      },
      {
        property: 'twitter:site',
        content: '@Marqeta',
      },
      {
        property: 'twitter:url',
        content: url,
      },
      {
        property: 'twitter:title',
        content: title,
      },
      {
        property: 'twitter:image',
        content: ogImageUrl,
      },
      {
        name: 'msapplication-TileColor',
        content: '#2a206a',
      },
      {
        name: 'msapplication-TileImage',
        content: `${branch}/mstile-144x144.png`,
      },
      {
        name: 'theme-color',
        content: '#2a206a',
      },
    ];

    if (desc) {
      meta = meta.concat([
        {
          name: 'description',
          content: desc,
        },
        {
          name: 'og:description',
          content: desc,
        },
        {
          name: 'twitter:description',
          content: desc,
        },
      ]);
    }

    if (redirectUrl) {
      meta.push({
        'http-equiv': 'refresh',
        content: `0;URL=${redirectUrl}`,
      });
    }

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        meta={meta}
      >
        <title>{title} | Marqeta Docs</title>
        <link rel="canonical" href={url} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${branch}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${branch}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${branch}/favicon-16x16.png`} />
        <link rel="mask-icon" href={`${branch}/safari-pinned-tab.svg`} color="#2a206a" />
      </Helmet>
    );
  }
}

SEO.propTypes = {
  lang: PropTypes.string,
  url: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string,
  og: PropTypes.object,
  twitter: PropTypes.object,
  redirectUrl: PropTypes.string,
  status: PropTypes.string,
};

SEO.defaultProps = {
  lang: 'en',
  locale: 'en_US',
};

export default SEO;

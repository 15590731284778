import gqlApi from './GqlApi';

const emptyAuthState = null;
const emptyInfoState = null;
const emptySandboxState = { job_state: null };

const effectfullyFetchUserSandboxes = () =>
  gqlApi.gqlQuery(`query {
    currentUser {
      token
      first_name
      last_name
      email
      phone
      sandboxes {
        data {
          jobs {
            data {
              state
            }
          }
          credentials {
            data {
              access_token
              application_token
            }
          }
        }
      }
    }
  }`);

const effectfullyFetchUserSandboxesWithoutCreds = () =>
  gqlApi.gqlQuery(`query {
    currentUser {
      token
      first_name
      last_name
      email
      phone
      sandboxes {
        data {
          jobs {
            data {
              state
            }
          }
        }
      }
    }
  }`);

// Returns the current users last sandbox created if it exists
const extractSandboxData = (responseData) => {
  try {
    const isUndefined = typeof responseData === 'undefined';
    if (isUndefined) return { errors: 'No data received from API.' };
    const { data } = responseData;
    const isCurrentUser = data.hasOwnProperty('currentUser');
    const isCreateSandbox = data.hasOwnProperty('createSandbox');
    if (isCurrentUser) {
      return data.currentUser.sandboxes.data[0] || {};
    } else if (isCreateSandbox) {
      return data.createSandbox || {};
    }
  } catch (e) {
    return {};
  }
};

// Auth functions
const getAuthData = (sandboxData) => {
  const auth = extractAuthCredentials(sandboxData);
  return transformAuthCredentials(auth);
};

const extractAuthCredentials = (sandboxData) => {
  try {
    return sandboxData.credentials.data[0] || emptyAuthState;
  } catch (e) {
    return emptyAuthState;
  }
};

const transformAuthCredentials = (auth) => {
  if (!auth) return auth;
  const { application_token: app_token, access_token: auth_token } = auth;
  const transformed = {};
  if (app_token) transformed.app_token = app_token;
  if (auth_token) transformed.auth_token = auth_token;
  transformed.base_url = process.env.GATSBY_SANDBOX_BASE_URL;
  return transformed;
};

// Job Functions
const extractJob = (sandboxData) => {
  try {
    return sandboxData.jobs.data[0] || emptySandboxState;
  } catch (e) {
    return emptySandboxState;
  }
};

const transformJob = (jobData) => {
  if (!jobData.state) return jobData;
  const transformed = {};
  transformed.job_state = jobData.state;
  return transformed;
};

const getJob = (sandboxData) => {
  const jobData = extractJob(sandboxData);
  return transformJob(jobData);
};

// User info functions
const extractUserInfo = (response) => {
  try {
    return response.data.currentUser || emptyInfoState;
  } catch (e) {
    return emptyInfoState;
  }
};

const getUserInfo = (response) => {
  return extractUserInfo(response);
};

// TODO: write tests. User info compiles to the following:
// {
//      auth:    {base_url, app_token, auth_token},
//      info:    {email, first_name, last_name},
//      sandbox: {job_state},
// }
const compileLatestStatus = (queryResponse) => {
  const info = getUserInfo(queryResponse);
  const sandboxData = extractSandboxData(queryResponse);
  const sandbox = getJob(sandboxData);
  const auth = getAuthData(sandboxData);
  return { data: { auth, info, sandbox } };
};

const getUserSandboxData = async () => {
  const queryResponse = await effectfullyFetchUserSandboxes();
  return compileLatestStatus(queryResponse);
};

const getUserSandboxState = async () => {
  const queryResponse = await effectfullyFetchUserSandboxesWithoutCreds();
  const sandboxData = extractSandboxData(queryResponse);
  const sandboxJob = getJob(sandboxData);
  const info = getUserInfo(queryResponse);
  return { data: { info, job_state: sandboxJob.job_state } };
};

export default { getUserSandboxData, getUserSandboxState };

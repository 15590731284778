import React from 'react';
import { Colors, Text, VSpacer, LoadingOverlay } from '@mqd/volt-base';
import { CodeBlock } from '@mqd/volt-codeblock';
import { utilService } from '../../../services';
import * as s from '../ApiWidget.module.css';

const Response = ({ sending, status, body }) => {
  const bodyString = (body && JSON.stringify(body, undefined, 2)) || '';
  const successColor = Colors.statusSuccessColor;
  const errorColor = Colors.statusErrorColor;

  if (!sending && !status) return null;
  return (
    <>
      {utilService.renderIf(
        sending,
        <LoadingOverlay active={true} borderRadius={'small'}>
          <VSpacer factor={12} />
        </LoadingOverlay>
      )}

      {utilService.renderIf(
        !sending && !!status,
        <>
          <VSpacer factor={2} />
          <Text inline={true} type="h6">
            Response body
          </Text>
          <span
            className={s.ellipse}
            style={{
              background: status && status.toString().charAt(0) === '2' ? successColor : errorColor,
            }}
          ></span>
          <Text inline={true} testId="response-status">
            {status}
          </Text>
          <VSpacer />
          {utilService.renderIf(
            !!body,
            <CodeBlock
              content={[{ language: 'JSON', content: bodyString }]}
              testId="response-codeblock"
            >
              {bodyString}
            </CodeBlock>
          )}
        </>
      )}
    </>
  );
};

export default Response;

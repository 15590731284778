import React from 'react';
import * as s from './IframeComponent.module.css';
import PropTypes from 'prop-types';

const IframeComponent = ({ src, height, title }) => {
  return (<div className={`${s.container}`}>
    <iframe
      data-testid="iframe"
      src={`https://${src}`}
      {...{ height, title }}
      frameBorder="0"
      allowFullScreen
    />
    </div>
  );
}

IframeComponent.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default IframeComponent;
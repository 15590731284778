import { action, decorate, observable, computed, configure } from 'mobx';
import React from 'react';
import { Colors, GlobalAlert, Text, Link } from '@mqd/volt-base';
import CookieNotice from '../components/cookie_notice/CookieNotice';
import { userStore } from './';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class GlobalAlertStore {
  // observables
  alertsList = {
    //array of active alerts
    header: [],
    footer: [],
  };

  // static variables
  alerts = {
    sessionTimeoutWarning: ({ dismiss }) => (
      <GlobalAlert
        type="info"
        mounted
        confirmText="Refresh"
        dismiss={dismiss}
        testId="sessionTimeoutWarning"
      >
        Your session will expire soon due to inactivity. To stay logged in, click{' '}
      </GlobalAlert>
    ),
    sessionTimedoutNotice: ({ dismiss }) => (
      <GlobalAlert type="info" mounted dismiss={dismiss}>
        Your session has expired due to inactivity. To continue working, click{' '}
        <Text type="h6" inline={true} color={Colors.actionColorLighter1}>
          Sign In
        </Text>{' '}
        at the top right of the page
      </GlobalAlert>
    ),
    cookieNotice: ({ dismiss }) => (
      <CookieNotice dismiss={dismiss}>
        <Text>
          We use cookies to enhance your experience on this site.
          <br /> By continuing to use this site, you accept our{' '}
          <Link newTab={true} href={`${process.env.GATSBY_MARQETA_URL}/cookie-policy`}>
            cookie policy
          </Link>
          .
        </Text>
      </CookieNotice>
    ),
  };

  // conditions to be met for some of the alerts in order to add them
  conditions = {
    sessionTimeoutWarning: () => !!userStore.loggedIn, // user must be logged in
    sessionTimedoutNotice: () => !!userStore.loggedIn, // user must be logged in
  };

  addAlert(alertName, position, dismissCB) {
    // if an alert has a condition, and it isn't met, then don't do anything
    if (this.conditions[alertName] && !this.conditions[alertName]()) {
      return;
    }

    const alert = this.alerts[alertName]({
      dismiss: () => {
        this.dismissAlert(alertName, position);
        dismissCB && dismissCB();
      },
      position: position === 'footer' ? 'bottom' : 'top',
    });

    if (!alert) return;
    this.alertsList[position].push({
      name: alertName,
      component: alert,
    });
  }

  dismissAlert(alertName, position) {
    const alert = this.alerts[alertName];
    if (!alert) return;
    this.alertsList[position] = this.alertsList[position].filter(
      (alert) => alert.name !== alertName
    );
  }

  get latestHeaderAlert() {
    const alerts = this.alertsList['header'];
    if (alerts.length) {
      const alert = alerts[alerts.length - 1].component;
      return alert;
    } else {
      return null;
    }
  }

  get latestFooterAlert() {
    const alerts = this.alertsList['footer'];
    if (alerts.length) {
      const alert = alerts[alerts.length - 1].component;
      return alert;
    } else {
      return null;
    }
  }

  get headerAlertPresent() {
    return !!this.alertsList['header'].length;
  }

  get footerAlertPresent() {
    return !!this.alertsList['footer'].length;
  }
}

decorate(GlobalAlertStore, {
  // values
  alertsList: observable,

  //actions
  addAlert: action.bound,
  dismissAlert: action.bound,

  // computed
  latestHeaderAlert: computed,
  latestFooterAlert: computed,
  headerAlertPresent: computed,
  footerAlertPresent: computed,
});

const globalAlertStore = new GlobalAlertStore();
// expose this store when running in Cypress
if (typeof window !== 'undefined' && window.Cypress) {
  window.globalAlertStore = globalAlertStore;
}
export default globalAlertStore;

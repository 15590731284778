import { action, decorate, observable, computed, configure, observe } from 'mobx';
import { find, head, not, pipe, prop, propEq } from 'ramda';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class PageDataStore {
  // observables
  pageInfo = {};
  siteTheme =
    (typeof window !== 'undefined' && window.localStorage.getItem('siteTheme')) || 'voltLightCode';

  // static variables
  siteMapInfo = [];

  // will run callback when page is changed
  onPageChange(callback) {
    observe(this, 'pageInfo', (change) => {
      if (change.oldValue.urlPath !== change.newValue.urlPath) {
        callback();
      }
    });
  }

  get pagePath() {
    return this.pageInfo && this.pageInfo.urlPath;
  }

  setPageInfo(data) {
    this.pageInfo = data;
  }

  setSiteMapInfo(data) {
    this.siteMapInfo = data;
  }

  setSiteTheme(theme) {
    this.siteTheme = theme;
    window.localStorage.setItem('siteTheme', theme);
  }

  // Takes in a collection name, and the menuTree and returns the first page path of that collection
  getFirstPath(cPath) {
    const siteMapInfo = this.siteMapInfo.filter((collection) => !collection.staticLink);
    const match = siteMapInfo.find((collection) => collection.urlPath === cPath);
    if (!match) return null;
    return pipe(
      prop('categories'),
      head,
      prop('pages'),
      find(pipe(propEq('status', 'hidden'), not)),
      prop('slug')
    )(match);
  }
}

decorate(PageDataStore, {
  // values
  pageInfo: observable,
  siteTheme: observable,

  //actions
  setPageInfo: action.bound,
  setSiteMapInfo: action.bound,
  setSiteTheme: action.bound,

  // computed
  pagePath: computed,
});

const pageDataStore = new PageDataStore();
export default pageDataStore;

import React from 'react';
import { SidenavIcon } from '../';
import { Colors, Text, VSpacer } from '@mqd/volt-base';
import { utilService } from '../../services';
import * as s from './LandingPageHeader.module.css';

const LandingPageHeader = ({ title, category, description }) => (
  <div className={s.container} data-testid="landing-page-header">
    <Text type="h1" className={s.title}>
      <IconCard iconName={category} />
      {title}
    </Text>
    <Text type="body-lg">{description}</Text>
  </div>
);

const IconCard = ({ iconName }) => {
  const iconString = utilService.slugify(iconName);
  return (
    <div className={s.iconContainer}>
      <SidenavIcon type={iconString} color={Colors.blackLighter1} />
    </div>
  );
};

export default LandingPageHeader;

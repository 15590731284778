import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { HSpacer, Icon, Text, VSpacer } from '@mqd/volt-base';
import ReferenceIcon from '../../../static/ReferenceIcon.svg';
import ExplorerIcon from '../../../static/ExplorerIcon.svg';

import * as s from './TopNav.module.css';

const TopNav = ({ links, testId }) => {
  const getLinks = () => {
    return links.map((link, i) => {
      return (
        <div className={s.link} key={i}>
          <div
            className={`${s.content} ${link.active ? s.active : ''}`}
            data-testid="topnav-link-content"
          >
            {link.title === 'Guides' || link.title === 'Test' ? (
              <Link to={link.contentLink}>{link.title}</Link>
            ) : (
              <Dropdown
                title={link.title}
                contentLink={link.contentLink}
                apiExplorer={link.apiExplorer}
                description={link.description}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const Dropdown = ({ title, contentLink, apiExplorer, description }) => {
    return (
      <div className={s.dropdown} data-testid="dropdown">
        <div className={s.dropdownTitle}>
          {title}
          <HSpacer factor={0.5} />
          <Icon type="caret-down" factor={1} noHoverEffects={true} hasTooltip={false} />
        </div>
        <div className={s.dropdownContainer} data-testid="dropdown-container">
          {description && <Text type="body-sm">{description}</Text>}
          <VSpacer factor={3} />
          {renderReferenceLink(title, contentLink)}
          {apiExplorer && renderExplorerLink(title, apiExplorer)}
        </div>
      </div>
    );
  };

  const renderReferenceLink = (title, link) => {
    return (
      <Link to={link} className={s.dropdownLinkSection}>
        <div className={s.dropdownLinkIcon}>
          <img src={ReferenceIcon} />
        </div>
        <div className={s.dropdownLinkText}>
          <Text className={s.dropdownLinkTitle} type="h6">
            {title} Reference
          </Text>
          <Text type="body-sm">Endpoint definitions and field-level reference</Text>
        </div>
      </Link>
    );
  };

  const renderExplorerLink = (title, link) => {
    return (
      <>
        <VSpacer factor={3} />
        <Link to={link} className={s.dropdownLinkSection}>
          <div className={s.dropdownLinkIcon}>
            <img src={ExplorerIcon} />
          </div>
          <div className={s.dropdownLinkText}>
            <Text className={s.dropdownLinkTitle} type="h6">
              {title} Explorer
            </Text>
            <Text type="body-sm">Try out all endpoints from your browser</Text>
          </div>
        </Link>
      </>
    );
  };

  return (
    <div className={s.wrap} data-testid={testId}>
      {getLinks()}
    </div>
  );
};

TopNav.propTypes = {
  links: PropTypes.array,
  testId: PropTypes.string,
};

TopNav.defaultProps = {
  links: [],
  testId: '',
};

export default TopNav;

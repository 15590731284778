import React from 'react';
import { Colors, Link } from '@mqd/volt-base';
import { parseDescription } from './utils';
import * as s from './Table.module.css';

const TableCell = ({ cellType, value, parent, clickHandler }) => {
  const colorMapping = {
    string: Colors.aaBurnt,
    object: Colors.aaMagenta,
    number: Colors.actionColorDarker2,
    integer: Colors.actionColorDarker2,
    array: Colors.aaGreen,
    boolean: Colors.aaBlue,
  };
  switch (cellType) {
    case 'name':
      return <td>{value}</td>;
    case 'description':
      return (
        <td
          style={{ color: Colors.blackLighter3 }}
          className={s.description}
          dangerouslySetInnerHTML={{ __html: parseDescription(value) }}
        ></td>
      );
    case 'type':
      return (
        <td
          style={{
            color: colorMapping[value] || '',
          }}
        >
          {value}
        </td>
      );
    case 'required':
      const optional = !value;
      return (
        <td style={{ color: optional ? Colors.blackLighter5 : Colors.blackLighter3 }}>
          {optional ? 'optional' : 'required'}
        </td>
      );
    case 'children':
      return (
        <td>
          {value ? (
            <span className={s.childLink}>
              <Link
                onClick={() => {
                  clickHandler(value, parent);
                }}
                newTab={false}
                iconType="arrow-right"
              >
                View
              </Link>
            </span>
          ) : (
            ''
          )}
        </td>
      );
  }
  return null;
};

export default TableCell;

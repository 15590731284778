import React, { useState } from 'react';
import { Tabs } from '@mqd/volt-base';
import SendRequestTab from './SendRequestTab';
import RequestTab from './RequestTab';
import ResponseTab from './ResponseTab';
import { path, pathOr } from 'ramda';
import { utilService } from '../../../services';
import * as s from '../ApiWidget.module.css';

const EndpointInfo = ({ info, endpoint, method, visible }) => {
  const requestInfo = info['Request definition'];
  const responseInfo = info['Response definition'];

  const SEND_REQUEST = 'Send request';
  const REQUEST_DEFINITION = 'Request definition';
  const RESPONSE_DEFINITION = 'Response definition';

  const sampleRequestBody = pathOr('', ['Request Body', 'sample request body'], requestInfo);
  const tabs = path(['Request Body'], requestInfo)
    ? [SEND_REQUEST, REQUEST_DEFINITION, RESPONSE_DEFINITION]
    : [SEND_REQUEST, RESPONSE_DEFINITION];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [requestBody, setRequestBody] = useState(sampleRequestBody);

  return (
    <div
      data-testid="expanded-info"
      className={s.endpointInfo}
      style={{
        display: visible ? 'block' : 'none',
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? '' : 'none',
      }}
    >
      <Tabs activeTab={activeTab} tabs={tabs} onTabChange={setActiveTab} />
      {utilService.renderIf(
        activeTab === SEND_REQUEST,
        <SendRequestTab
          endpoint={endpoint}
          method={method}
          requestInfo={requestInfo}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
        />
      )}
      {utilService.renderIf(
        activeTab === REQUEST_DEFINITION,
        <RequestTab requestInfo={requestInfo} />
      )}
      {utilService.renderIf(
        activeTab === RESPONSE_DEFINITION,
        <ResponseTab responseInfo={responseInfo} />
      )}{' '}
    </div>
  );
};

export default EndpointInfo;

import React, { Component } from 'react';
import { useImages } from './useImages';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { lightboxStore } from '../../stores';
import { observer } from 'mobx-react';
import { FeedbackWidget } from '../';

class Image extends Component {
  constructor(props) {
    super(props);
    this.img = React.createRef();
  }

  get style() {
    const { width, feedbackId } = this.props;
    const opacity = lightboxStore.lightboxImage ? 0 : 1;
    return {
      maxWidth: Number(width) || 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 32,
      marginBottom: feedbackId ? 0 : 40,
      display: 'block',
      opacity,
    };
  }

  renderSvg() {
    const { alt, node, feedbackId } = this.props;
    return (
      <>
        <img
          className="gatsby-svg"
          src={node.publicURL}
          alt={alt}
          title={alt}
          style={this.style}
          onClick={this.handleClick}
          ref={this.img}
        />
        {feedbackId && <FeedbackWidget feedbackId={feedbackId} type="image" />}
      </>
    );
  }

  handleClick = () => {
    if (!this.img || !this.img.current) return;
    lightboxStore.setLightboxImage(this.img.current);
  };

  render() {
    const { alt, node, feedbackId } = this.props;
    if (node.extension === 'svg') {
      return this.renderSvg();
    }

    return (
      <>
        <div onClick={this.handleClick} ref={this.img}>
          <GatsbyImage image={getImage(node)} style={this.style} alt={alt} title={alt} />
        </div>
        {feedbackId && <FeedbackWidget feedbackId={feedbackId} type="image" />}
      </>
    );
  }
}

const staticQueryImage = ({ imgName, alt, width, feedbackId }) => {
  const allImages = useImages();
  // Need to figure out way to check if client has a retina display!
  const image = allImages.edges.find((edge) => edge.node.name === imgName);
  if (!image) {
    console.error(`Could not find image with name ${imgName}.`);
    return <img alt={alt} />;
  }
  const ObservingImage = observer(Image);
  return <ObservingImage node={image.node} width={width} alt={alt} feedbackId={feedbackId} />;
};

export default React.memo(staticQueryImage);

import React from 'react';
import { path } from 'ramda';
import { Text, VSpacer } from '@mqd/volt-base';
import Table from '../table/Table';

const RequestTab = ({ requestInfo }) => {
  return (
    <div>
      {path(['Request Body'], requestInfo) && (
        <>
          <VSpacer factor={3} />
          <Text type="h6">Request body</Text>
          <VSpacer factor={0.5} />
          <Table rows={path(['Request Body'], requestInfo)} parent={['request object']} />
        </>
      )}
    </div>
  );
};

export default RequestTab;

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { globalAlertStore, pageDataStore } from '../../stores';
import {
  Header,
  Lightbox,
  Pagenav,
  RelatedDocs,
  Sidenav,
  GlobalToast,
  GlobalModal,
  GlobalAlert,
  Footer,
  FooterNewsletterWidget,
  BackToMarqeta,
} from '../../components/index';
import './layout.css';
import { desktop, divider } from '../../styles/Base.module.css';
import { utilService } from '../../services';
import { Container } from '@mqd/volt-base';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.navs = [];
    this.offsetNavBool = false;
    this.windowWidth = 0;
    this.debouncedBrowserResize = utilService.debounced(250, this.setScrollableSidebarSnap);
    this.minWindowScrollWidth = 1024;
    this.maxWindowScrollWidth = 1280;
  }

  componentDidMount() {
    this.navs = Array.from(document.getElementsByClassName('mqdocs-nav'));
    window.addEventListener('scroll', this.setSidebarSnap);
    window.addEventListener('resize', this.debouncedBrowserResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setSidebarSnap);
    window.removeEventListener('resize', this.debouncedBrowserResize);
  }

  setScrollableSidebarSnap = () => {
    this.windowWidth = window.innerWidth;
    if (
      this.windowWidth <= this.maxWindowScrollWidth &&
      this.windowWidth >= this.minWindowScrollWidth
    ) {
      this.navs.forEach((nav) => {
        window.requestAnimationFrame(() => {
          nav.style.removeProperty('transform');
        });
      });
    }
  };

  setSidebarSnap = () => {
    if (
      this.windowWidth > this.maxWindowScrollWidth ||
      this.windowWidth < this.minWindowScrollWidth
    ) {
      const shouldOffset = window.scrollY >= 24;
      if (this.offsetNavBool !== shouldOffset) {
        this.offsetNavBool = shouldOffset;
        this.navs.forEach((nav) => {
          window.requestAnimationFrame(() => {
            nav.style.transform = `translateY(${shouldOffset ? -16 : 0}px)`;
          });
        });
      }
    }
  };

  getOffsetStyle(maxHeightOffset = 152) {
    return {
      position: 'fixed',
      transition: 'transform .2s ease-out',
      maxHeight: `calc(100vh - ${maxHeightOffset}px)`,
      overflowY: 'auto',
    };
  }

  getOffsetPagenavStyle() {
    return this.getOffsetStyle(100);
  }

  getSideNav() {
    return (
      <div className={`layout-sidenav ${desktop}`}>
        <div style={this.getOffsetStyle()} className="mqdocs-nav">
          <Sidenav />
        </div>
        <BackToMarqeta />
      </div>
    );
  }

  getHomeContent(children) {
    return (
      <main className="layout-main volt-body layout-home">
        <Container gutter={0} padding={0}>
          {children}
        </Container>
        <Footer />
      </main>
    );
  }

  getRegularContent(children, status) {
    return (
      <>
        <main className="layout-main volt-body" data-status={status}>
          {children}
          <div className="layout-related">
            <RelatedDocs />
          </div>
          <div className={divider} />
          <FooterNewsletterWidget />
          <Footer />
        </main>
        <div className={`layout-pagenav ${desktop}`}>
          <div style={this.getOffsetPagenavStyle()} className="mqdocs-nav">
            <Pagenav />
          </div>
        </div>
      </>
    );
  }

  render() {
    const { children, is404, isMinimal, isHomeLayout } = this.props;
    const { headerAlertPresent } = globalAlertStore;
    const { pageInfo } = pageDataStore;
    const layoutBodyClasses = [
      'layout-body',
      is404 ? 'fof' : '',
      isMinimal ? 'minimal' : '',
      headerAlertPresent ? 'header-alert' : '',
    ].join(' ');
    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://customer.cludo.com/css/templates/v1.1/essentials/cludo-search.min.css"
          />
          <script
            src="https://customer.cludo.com/scripts/bundles/search-script.min.js"
            type="text/javascript"
          />
        </Helmet>
        <div className="layout-wrap">
          <Header />
          <div
            style={{
              background: 'linear-gradient(90deg, #f8f9f9 50%, #FFFFFF 50%)',
              minHeight: 'calc(100vh - 128px)',
            }}
          >
            <div className={layoutBodyClasses}>
              {(is404 || isMinimal) && children}
              {!is404 && !isMinimal && (
                <>
                  {isHomeLayout
                    ? this.getHomeContent(children)
                    : this.getRegularContent(children, pageInfo.status)}
                  {this.getSideNav()}
                </>
              )}
            </div>
          </div>
        </div>
        <Lightbox />
        <GlobalToast />
        <GlobalModal />
        <GlobalAlert.footer />
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(Layout);

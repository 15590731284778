// For Google's reCaptcha DDOS prevention
import axios from 'axios';
import { userStore, pageDataStore } from '../stores';
import { utilService } from '../services';

export default {
  formContentFeedback(info, token) {
    const {
      info: { auth: authInfo, info: userInfo },
    } = userStore;
    const { feedbackId, feedback, email, helpfulBool, type } = info;

    return {
      email: email || (userInfo && userInfo.email) || '',
      first_name: (userInfo && userInfo.first_name) || '',
      last_name: (userInfo && userInfo.last_name) || '',
      app_token: (authInfo && authInfo.app_token) || '',
      element_id: feedbackId || '',
      element_type: type,
      feedback: (feedback && feedback.trim()) || '',
      'g-recaptcha-response': token,
      was_helpful: !!helpfulBool ? 'yes' : 'no',
      device_id: utilService.ensureDeviceId() || 'not found',
      page: pageDataStore.pagePath,
    };
  },

  pageFeedback(info, token) {
    const {
      info: { auth: authInfo, info: userInfo },
    } = userStore;
    const { feedback, email } = info;

    return {
      email: email || (userInfo && userInfo.email) || '',
      first_name: (userInfo && userInfo.first_name) || '',
      last_name: (userInfo && userInfo.last_name) || '',
      app_token: (authInfo && authInfo.app_token) || '',
      feedback: (feedback && feedback.trim()) || '',
      'g-recaptcha-response': token,
      device_id: utilService.ensureDeviceId() || 'not found',
      page: pageDataStore.pagePath,
    };
  },

  renderCaptcha() {
    if (!document.querySelector('.grecaptcha-badge')) {
      window.grecaptcha.render(
        'recaptcha-container',
        {
          sitekey: '6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy',
          callback: 'captchaSubmit',
          'error-callback': 'captchaFail',
          'expired-callback': 'captchaFail',
          size: 'invisible',
          badge: 'inline',
        },
        false
      );
    }
    // clicks on the recaptcha iframe don't register on document,
    // so any registered click will suffice to trigger the captchaFail
    document.addEventListener('mouseup', window.captchaFail);
  },

  submit(info, successCB, failCB) {
    // need to set these to the window so it can be used in the captcha callbacks
    window.captchaFail = () => {
      setTimeout(failCB, 500); // small delay so the feedback widget doesn't disappear so soon
      window.grecaptcha.reset();
      document.removeEventListener('mouseup', window.captchaFail);
    };

    window.captchaSubmit = (token) => {
      successCB && successCB();
      let url;
      let params;

      if (info.feedbackId) {
        // codeblock or image feedback
        url = 'https://usebasin.com/f/8193bf681b48.json';
        params = this.formContentFeedback(info, token);
      } else {
        // general page feedback
        url = 'https://usebasin.com/f/d7ee3683332d.json';
        params = this.pageFeedback(info, token);
      }

      axios({
        method: 'POST',
        url: url,
        params: params,
        dataType: 'json',
      })
        .then((res) => {
          if (process.env.GATSBY_ENV !== 'DEV') {
            window.grecaptcha.reset();
          }
        })
        .catch((err) => {
          if (process.env.GATSBY_ENV !== 'DEV') {
            window.grecaptcha.reset();
          }
        });
    };

    if (process.env.GATSBY_ENV !== 'DEV') {
      this.renderCaptcha();
    } else {
      window.captchaSubmit();
    }

    function fixIframe(attempt = 0) {
      if (attempt >= 15) return;
      attempt++;
      const iframe = document.querySelector("iframe[title='recaptcha challenge']");
      if (!iframe) {
        setTimeout(function () {
          fixIframe(attempt);
        }, 200);
      } else {
        iframe.parentElement.style.position = 'fixed';
      }
    }

    fixIframe();

    const interval = setInterval(function () {
      if (document.querySelector('.grecaptcha-badge')) {
        clearInterval(interval);
        window.grecaptcha.execute();
      }
    }, 100);
  },
};

import React from 'react';
import * as s from './ContentHeader.module.css';
import { Text, Colors, Icon, HSpacer } from '@mqd/volt-base';

const ContentHeader = ({ readTime, lastModified }) => (
  <div className={s.docHeader}>
    <span className={s.headerItem} style={{ display: 'flex', alignItems: 'center' }}>
      <Icon type="clock" factor={0.25} overrideColor={Colors.blackLighter4} />
      <HSpacer factor={0.5} />
      <Text type="footnote" inline={true} color={Colors.blackLighter3} testId="read-time">
        {readTime} minute read{' '}
      </Text>
    </span>
    <span className={s.headerItem} style={{ display: 'flex', alignItems: 'center' }}>
      <Text type="footnote" inline={true} color={Colors.blackLighter3} testId="last-modified-date">
        {lastModified}
      </Text>
    </span>
  </div>
);

export default React.memo(ContentHeader);

import React from 'react';
import { Link } from 'gatsby';
import { activeLink, landingLinkContainer, landingLinkText } from '../../styles/Base.module.css';
import { Text } from '@mqd/volt-base';

function LandingPageLink({ link, text }) {
  return (
    <Link to={link} className={landingLinkContainer}>
      <Text className={`${activeLink} ${landingLinkText}`} type="h6" inline={false}>
        {text}
      </Text>
    </Link>
  );
}

export default LandingPageLink;

import React, { Component } from 'react';
import { VSpacer } from '@mqd/volt-base';
import { observer } from 'mobx-react';
import { pageDataStore, eventsStore } from '../../stores';
import { CodeBlock as VCodeBlock, LIGHT_CODE_THEME } from '@mqd/volt-codeblock';
import { FeedbackWidget } from '../';
import * as s from './CodeBlock.module.css';

class CodeBlock extends Component {
  render() {
    const { codewrap, content, shownums, numlines, lang, feedbackId, additionalLanguages = [] } = this.props;
    // defaults to light theme on mobileMode, with no option to change it currently.
    const theme = eventsStore.mobileMode ? LIGHT_CODE_THEME : pageDataStore.siteTheme;
    return (
      <>
        <VSpacer factor={1} />
        <span className={s.codeBlock} data-testid="codeblock">
          <VCodeBlock
            showLineNumbers={shownums}
            previewLineCount={numlines}
            codeWrap={codewrap}
            theme={theme}
            onColorToggle={(theme) => {
              pageDataStore.setSiteTheme(theme);
            }}
            content={[{ language: lang, content: content }, ...additionalLanguages]}
          ></VCodeBlock>
        </span>
        {feedbackId && <FeedbackWidget feedbackId={feedbackId} type="code" />}
        {!feedbackId && <VSpacer factor={2} />}
      </>
    );
  }
}

export default observer(CodeBlock);

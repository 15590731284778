function attachSearch() {
  if (typeof window.Cludo === 'undefined') {
    return setTimeout(attachSearch, 200);
  }
  const cludoSettings = {
    customerId: 10000179,
    engineId: 10001277,
    searchUrl: `${process.env.GATSBY_DOCS_URL}/search`,
    language: 'en',
    searchInputs: ['header-search-form', 'searchpage-search-form'],
    type: 'inline',
    results: 4,
    searchApiUrl: 'https://api-us1.cludo.com/api/v3',
  };
  const CludoSearch = new Cludo(cludoSettings);
  CludoSearch.init();

  CludoSearch._track = CludoSearch.track;

  // Override cludo GA track default behavior.
  CludoSearch.track = function () {
    const query = CludoSearch.params.query;
    window.analytics && window.analytics.track('Documentation Searched', { term: query });
    CludoSearch._track();
  };
}

export default { attachSearch };

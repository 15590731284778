import { action, decorate, observable, computed, configure } from 'mobx';
import React from 'react';
import { FeedbackModal } from '../components';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class GlobalModalStore {
  // observables
  modalInfo = null; // modal that should appear on screen

  // static variables
  modals = {
    feedback: (hideModal, options) => <FeedbackModal hideModal={hideModal} options={options} />,
  };

  // specific conditions for showing modals
  showConditions = {
    feedback: () => true,
  };

  /**
   *
   * @param {string} modalName - name of modal to open
   * @param {object} options - additional options to pass to modal
   */
  setModal(modalName, options) {
    const modal = this.modals[modalName](() => {
      this.dismissModal(modalName);
    }, options);

    if (modal && !!this.showConditions[modalName]()) {
      this.modalInfo && this.dismissModal(this.modalInfo.name); // dismiss existing modal if present
      this.modalInfo = {
        name: modalName,
        component: modal,
      };
    } else {
      this.modalInfo = null;
    }
  }

  dismissModal(modalName) {
    if (this.modalInfo.name === modalName) {
      this.modalInfo = null;
    }
  }

  get currentModal() {
    return this.modalInfo && this.modalInfo.component;
  }
}

decorate(GlobalModalStore, {
  // values
  modalInfo: observable,

  //actions
  setModal: action.bound,
  dismissModal: action.bound,

  //computed
  currentModal: computed,
});

const globalModalStore = new GlobalModalStore();
export default globalModalStore;

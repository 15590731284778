import React, { Component } from 'react';
import { pageDataStore } from '../../stores';
import { SidenavAccordion, SidenavList } from '../';
import { observer } from 'mobx-react';
import * as s from './Sidenav.module.css';

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.menuTree = pageDataStore.siteMapInfo;
    this.sidebarStyle;
    this.data;
  }

  render() {
    return <nav className={s.sidenav}>{this.renderCategories()}</nav>;
  }

  setInfo() {
    const page = pageDataStore.pageInfo;
    const currentArea = this.menuTree.find((area) => area.urlPath === page.urlCollection);
    this.sidebarStyle = currentArea.sidebarStyle;
    this.data = currentArea.categories;
  }

  renderCategories() {
    this.setInfo();
    const activePage = pageDataStore.pagePath;
    const tabName = activePage && activePage.split('/')[1];
    const categoryContainsActivePage = (category) =>
      category.pages.find((page) => activePage === page.urlPath);
    const activeCategory = `${tabName}-${this.data.findIndex((category) =>
      categoryContainsActivePage(category)
    )}`;
    return (
      <SidenavAccordion
        width="auto"
        activeTab={tabName}
        activeCategory={activeCategory}
        hasIcons={tabName === 'developer-guides'}
        testId="sidenav-accordion"
        sections={this.data.map((category, i) => ({
          title: category.name,
          key: `${tabName}-${i}`,
          content: (
            <SidenavList
              links={category.pages.filter((page) => page.status !== 'hidden')}
              hasSidebar={tabName === 'developer-guides'}
            />
          ),
          defaultExpanded:
            this.sidebarStyle === 'accordion' ? categoryContainsActivePage(category) : true,
        }))}
      />
    );
  }
}

export default observer(Sidenav);

import React, { useState, useEffect } from 'react';
import { isEmpty, path } from 'ramda';
import { Text, VSpacer } from '@mqd/volt-base';
import Table from '../table/Table';
import { CodeBlock } from '@mqd/volt-codeblock';
import * as s from '../ApiWidget.module.css';

const ResponseTab = ({ responseInfo }) => {
  const responseTypes = responseInfo.map((response) => response.type);
  const [responseType, setResponseType] = useState(responseTypes[0]);
  const [responseObject, setResponseObject] = useState({});
  useEffect(() => {
    setResponseObject(responseInfo.find((info) => info.type == responseType));
  }, [responseType]);

  return (
    <div>
      <VSpacer factor={3} />
      <Text type="h6">Select response</Text>
      <VSpacer factor={0.5} />

      <div className={s.responseSelector}>
        {responseTypes.map((type) => (
          <ResponseStatus
            key={type}
            type={type}
            responseType={responseType}
            setResponseType={setResponseType}
          />
        ))}
      </div>

      <VSpacer factor={2} />
      <CodeBlock
        content={[{ language: 'Response', content: responseObject.message }]}
        showLineNumbers={false}
      ></CodeBlock>

      {!isEmpty(path(['response object'], responseObject)) && (
        <>
          <VSpacer factor={3} />
          <Text type="h6">Response object</Text>
          <VSpacer factor={0.5} />
          <Table rows={responseObject} parent={['response object']} />
        </>
      )}
    </div>
  );
};

const ResponseStatus = ({ type, responseType, setResponseType }) => {
  const active = type === responseType;
  const success = type.charAt(0) === '2';
  const color = success ? '#3DB65C' : '#FF304A';
  return (
    <div
      className={`${s.responseType} ${active ? s.active : s.inactive}`}
      onClick={() => setResponseType(type)}
    >
      <span
        className={`${s.ellipse} ${success ? s.successEllipse : s.errorEllipse}`}
        style={{ background: active ? color : '' }}
      ></span>
      <Text inline={true}>{type}</Text>
    </div>
  );
};
export default ResponseTab;

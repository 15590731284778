import React from 'react';
import HtmlToReact from 'html-to-react';
import { Link } from 'gatsby';
import {
  CodeBlock,
  EndpointWidget,
  HeadingLink,
  FeedbackWidget,
  LandingPageHeader,
  IframeComponent,
  InlineImage,
  Image,
  GettingStartedSteps,
  HomeHeaderWidget,
  HomeMoreResourcesWidget,
  KeyConceptWidget,
  ReleaseNotesWidget,
} from '../components';
import { Admonition, Text, VSpacer, NumberedList, BulletedList, Row, Pill } from '@mqd/volt-base';

// returns clone with removed 'vbdy' class, and from all children
function cleanUpClass(elem) {
  if (React.isValidElement(elem)) {
    return React.cloneElement(elem, {
      className: elem.props.className && elem.props.className.replace('vbdy', ''),
      children: React.Children.map(elem.props.children, function (child) {
        return cleanUpClass(child);
      }),
    });
  } else {
    return elem;
  }
}

const hydrateAdmonitions = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs['id'] === 'gatsby-admonition';
  },
  processNode: function (node, children, index) {
    const { heading, type } = node.attribs;
    let content;

    // BFS to find the react element that has class name of 'content'
    function BFS(nodes) {
      for (let item of nodes) {
        if (content) return;
        if (item.props && item.props.className && item.props.className.includes('content')) {
          content = item;
        } else if (item.props && item.props.children) {
          BFS(item.props.children);
        }
      }
    }

    BFS(children);
    content = cleanUpClass(content);
    const bannerContent = content.props && content.props.children;

    return (
      <>
        <VSpacer factor={3} />
        <Admonition heading={heading} type={type}>
          <span className="docs-admonition">{bannerContent}</span>
        </Admonition>
        <VSpacer factor={3} />
      </>
    );
  },
};

const hydrateCodeBlocks = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    // Only hydrates internal links, anchor links should remain unchanged
    return node.attribs && node.attribs['id'] === 'gatsby-codeblock';
  },
  processNode: function (node, children, index) {
    const {
      shownums,
      numlines,
      lang,
      content,
      codewrap,
      linecount,
      'data-feedbackid': feedbackId,
      'additional-languages': additionalLanguages = '[]',
    } = node.attribs;
    const cleanedChildren = children.map((child) => cleanUpClass(child));
    return (
      <CodeBlock
        shownums={shownums === 'true'}
        numlines={numlines}
        lang={lang}
        content={content}
        codewrap={codewrap === 'true'}
        linecount={Number(linecount)}
        feedbackId={feedbackId.startsWith('undefined') ? null : feedbackId}
        additionalLanguages={JSON.parse(additionalLanguages)}
      >
        {cleanedChildren}
      </CodeBlock>
    );
  },
};

const hydrateCtaBanners = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-endpoint-widget');
  },
  processNode: function (node) {
    const {
      id,
      'data-api': api,
      'data-endpoint': endpoint,
      'data-method': method,
      'data-spec': spec,
    } = node.attribs;
    return (
      <>
        <EndpointWidget domId={id} api={api} endpoint={endpoint} method={method} spec={spec} />
      </>
    );
  },
};

const hydrateGettingStartedSteps = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-getting-started-steps');
  },
  processNode: function (node) {
    const { 'data-getting-started-steps-content': steps } = node.attribs;
    return <GettingStartedSteps steps={steps} />;
  },
};

const hydrateHomeHeader = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-home-header-widget');
  },
  processNode: function (node) {
    const { 'data-title': title, 'data-description': description } = node.attribs;
    return <HomeHeaderWidget title={title} description={description} />;
  },
};

const hydrateLandingPageHeader = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-landing-page-header-widget');
  },
  processNode: function (node) {
    const {
      'data-title': title,
      'data-category': category,
      'data-description': description,
    } = node.attribs;
    return <LandingPageHeader title={title} category={category} description={description} />;
  },
};

const hydrateVoltronRow = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-volt-row');
  },
  processNode: function (node, children) {
    if (!children.length) return;
    return <Row>{children}</Row>;
  },
};

const hydrateKeyConcept = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-key-concept-widget');
  },
  processNode: function (node) {
    const { 'data-key-concepts': keyConcepts } = node.attribs;
    return <KeyConceptWidget keyConcepts={keyConcepts} />;
  },
};

const hydrateReleaseNotes = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-release-notes-widget');
  },
  processNode: function (node) {
    const { 'data-release-notes': releaseNotes } = node.attribs;
    return <ReleaseNotesWidget releaseNotes={releaseNotes} />;
  },
};

const hydrateHomeMoreResources = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-home-more-resources-widget');
  },
  processNode: function (node) {
    const { 'data-resources': resources } = node.attribs;
    return <HomeMoreResourcesWidget data={resources} />;
  },
};

const hydrateHeadings = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.name && ['h2', 'h3', 'h4'].includes(node.name);
  },
  processNode: function (node, children, index) {
    const headingType = node.name;
    return (
      <Text id={node.attribs.id} type={headingType} testId={`heading-${headingType}`}>
        {children}
      </Text>
    );
  },
};

const hydrateTitle = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.name === 'h1';
  },
  processNode: function (node, children) {
    const isHiddenPage = node.attribs['data-status'] == 'hidden';
    return (
      <div style={{ display: 'inline-block', marginBottom: 16 }}>
        <Text id={node.attribs.id} type="h1" testId="page-title">
          {children}
        </Text>
        {isHiddenPage && (
          <Pill
            type="warning"
            style={{
              margin: '12px 8px 0',
              verticalAlign: 'top',
            }}
            className="page-title-hidden-label"
            testId="page-title-hidden-label"
          >
            Hidden
          </Pill>
        )}
      </div>
    );
  },
};

const hydrateHeadingLinks = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs.hasOwnProperty('data-heading-link');
  },
  processNode: function (node) {
    const hash = node.parent.attribs.id;
    return <HeadingLink hash={hash} />;
  },
};

const hydrateMedia = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && ['gatsby-video', 'gatsby-audio'].includes(node.attribs['id']);
  },
  processNode: function (node, children, index) {
    const props = node.attribs;
    const mediaType = props['id'].split('-')[1];
    const feedbackId = props['data-feedbackid'];
    const cleanedChildren = children.map((child) => cleanUpClass(child));
    return (
      <>
        {cleanedChildren}
        {!feedbackId.startsWith('undefined') && (
          <FeedbackWidget feedbackId={feedbackId} type={mediaType} />
        )}
      </>
    );
  },
};

const hydrateImages = {
  // Image swapping
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs['id'] === 'gatsby-image';
  },
  processNode: function (node, children, index) {
    const props = node.attribs;
    const feedbackId = props['data-feedbackid'];
    return (
      <Image
        imgName={props.name}
        key={index}
        alt={props.alt}
        width={props.width}
        feedbackId={feedbackId.startsWith('undefined') ? null : feedbackId}
      />
    );
  },
};

const hydrateInlineImages = {
  // Image swapping
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.attribs && node.attribs['id'] === 'gatsby-inline-image';
  },
  processNode: function (node, children, index) {
    const props = node.attribs;
    return <InlineImage imgName={props.name} key={index} alt={props.alt} width={props.width} />;
  },
};

const hydrateInlineCode = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.name === 'code';
  },
  processNode: function (node, children, index) {
    if (!children.length) return;
    return (
      <Text type="code" inline={true}>
        {children[0]}
      </Text>
    );
  },
};

const hydrateLinks = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    // Only hydrates internal links, anchor links should remain unchanged
    return node.attribs && node.attribs['data-internal-link'];
  },
  processNode: function (node, children, index) {
    return (
      <Link to={node.attribs.href} className="vbdy">
        {children}
      </Link>
    );
  },
};

const hydrateLists = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.name === 'ol' || node.name === 'ul';
  },
  processNode: function (node, children, index) {
    return node.name === 'ol' ? (
      <NumberedList>{children}</NumberedList>
    ) : (
      <BulletedList>{children}</BulletedList>
    );
  },
};

const hydrateListItems = {
  replaceChildren: false,
  shouldProcessNode: function (node) {
    return node.name === 'li';
  },
  processNode: function (node, children, index) {
    return node.parent.name === 'ol' ? (
      <NumberedList.ListItem key={index}>{children}</NumberedList.ListItem>
    ) : (
      <BulletedList.ListItem key={index}>{children}</BulletedList.ListItem>
    );
  },
};

const hydrateIframe = {
  replaceChildren: false,
  shouldProcessNode: (node) => {
    if (node.attribs?.hasOwnProperty('iframe-component')) {
      const ALLOWED_DOMAINS = ['marqeta.com', 'marqeta.io'];
      const { source } = node.attribs;
      try {
        const url = new URL(`https://${source}`);
        return ALLOWED_DOMAINS.reduce((hasMatched, domain) => hasMatched || url.hostname.endsWith(domain), false);
      } catch (error) {
        return false;
      }
    }
    return false;
  },
  processNode: (node) => {
    const { source, height, title } = node.attribs;
    return (
      <IframeComponent
        src={source}
        height={height}
        title={title}
      />
    );
  },
};

const defaultProcessing = {
  shouldProcessNode: () => true,
  processNode: function (node, children, index) {
    const html = HtmlToReact.ProcessNodeDefinitions(React).processDefaultNode(
      node,
      children,
      index
    );
    let htmlCopy = html;
    if (React.isValidElement(html)) {
      // add vbdy class to all elements from ascii html
      htmlCopy = React.cloneElement(htmlCopy, {
        className: `${html.props.className || ''} vbdy`,
      });
    }
    return htmlCopy;
  },
};

export default [
  hydrateAdmonitions,
  hydrateCodeBlocks,
  hydrateCtaBanners,
  hydrateHeadings,
  hydrateHomeMoreResources,
  hydrateTitle,
  hydrateHeadingLinks,
  hydrateHomeHeader,
  hydrateGettingStartedSteps,
  hydrateInlineImages,
  hydrateIframe,
  hydrateImages,
  hydrateMedia,
  hydrateInlineCode,
  hydrateKeyConcept,
  hydrateLandingPageHeader,
  hydrateLists,
  hydrateListItems,
  hydrateLinks,
  hydrateReleaseNotes,
  hydrateVoltronRow,
  defaultProcessing,
];

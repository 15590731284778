function flagIE() {
  if (
    Object.hasOwnProperty.call(window, 'ActiveXObject') &&
    !window.ActiveXObject
  ) {
    document.documentElement.classList.add('ie11');
  }
}

export default { flagIE };

import React, { useState, useReducer, useRef, useEffect } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import HtmlToReact, { Parser } from 'html-to-react';
import { Link } from 'gatsby';
import * as s from './Chatbot.module.css';
import {
  Button,
  Colors,
  Drawer,
  HSpacer,
  Icon,
  Text,
  Textarea,
  Tooltip,
  VSpacer,
} from '@mqd/volt-base';
import Popover from './Popover';
import { CodeBlock } from '@mqd/volt-codeblock';
import { sendChatMessage, sendFeedback } from './chatbotFunctions';
import { utilService } from '../../services';
import {
  SparkleIcon,
  FeedbackIcon,
  SendMessageIcon,
  ExpandIcon,
  MinimizeIcon,
  HelpIcon,
  CopyIcon,
} from './ChatbotIcons';
import TypingGIF from './typingGIF.gif';
import { useScrollPosition } from './hooks/hooks';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(!hasClosedPopover());
  const [expanded, setExpanded] = useState(false);
  const [query, setQuery] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    messages: [],
    loading: true,
  });
  const bodyRef = useRef(null);
  const [displayScrollButton, setDisplayScrollButton] = useState(false);
  const containerElement = document.querySelector("div[class^='Drawer-module__content']");
  const [isScrolling, scrollPosition] = useScrollPosition(containerElement);

  useEffect(() => {
    // Send first message 1.5 seconds after drawer is opened
    if (!state.messages.length && isOpen) {
      setTimeout(
        () =>
          dispatch({
            type: 'introMessage',
          }),
        1500
      );
    }
  }, [isOpen]);

  useEffect(() => {
    // Display scroll button if messages overflow container
    // Do not display while messages are loading
    setDisplayScrollButton(
      bodyRef?.current?.clientHeight > containerElement?.clientHeight && !state.loading
    );
  }, [state.messages, state.loading]);

  useEffect(() => {
    // Scroll to newest message when it's added
    scrollToLast('smooth');
  }, [state.messages.length]);

  useEffect(() => {
    // Autofocus textarea once message has finished loading
    if (!state.loading) {
      document.getElementById('query-textarea').focus();
    }
  }, [state.loading]);

  function reducer(state, action) {
    let newState;
    switch (action.type) {
      case 'introMessage':
        newState = {
          messages: [
            ...state.messages,
            {
              type: 'intro',
              text: "👋 Hello! I'm your AI-enabled assistant. Ask me anything related to Marqeta products or documentation.",
            },
          ],
          loading: false,
        };
        break;
      case 'newMessage':
        newState = {
          messages: [...state.messages, { type: 'query', text: action.query }],
          loading: true,
        };
        break;
      case 'newResponse':
        newState = {
          messages: [
            ...state.messages,
            {
              type: 'response',
              text: action.response,
              query: action.query,
              queryId: action.queryId,
              links: action.urlList,
              typing: action.typing,
            },
          ],
          loading: true,
        };
        break;
      case 'updateResponse':
        const { responseText, links = [], query = '', queryId = '', typing = true } = action;
        const messages = [...state.messages];
        const last = messages.pop();
        last.text = responseText;
        last.links = links;
        last.queryId = queryId;
        last.query = query;
        last.typing = typing;
        newState = {
          messages: [...messages, last],
          loading: typing, // if chatbot is still typing an answer, user can't ask another one
        };
        break;
      case 'newFeedback':
        newState = {
          messages: state.messages.map((message) =>
            message.queryId === action.queryId
              ? { ...message, userFeedback: action.feedback }
              : message
          ),
        };
        break;
      case 'clearHistory':
        newState = {
          messages: [],
          loading: true,
        };
        break;
      default:
        throw new Error();
    }
    return newState;
  }

  function hasClosedPopover() {
    return localStorage.getItem('chatbot-popover-dismissed');
  }

  const closePopover = () => {
    localStorage.setItem('chatbot-popover-dismissed', true);
    setTimeout(() => setShowPopover(false), 500);
  };

  const scrollToLast = (behavior = 'auto') => {
    containerElement?.scrollTo({ top: bodyRef?.current.scrollHeight, behavior: behavior });
  };

  const scrollToFirst = () => {
    containerElement?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  async function submitForm(e) {
    e.preventDefault();
    if (!query.trim()) return;
    document.getElementById('query-textarea') &&
      (document.getElementById('query-textarea').style.height = 'inherit'); // set textarea height back to default
    dispatch({ type: 'newMessage', query });
    const requestBody = {
      query: query.replace(/"/g, "'"), // double quotes will throw an error with the backend
      metadata: '{}', // hardcoded empty for now, will add data later
    };
    setQuery('');
    try {
      dispatch({
        type: 'newResponse',
        response: '',
        query: '',
        queryId: '',
        urlList: [],
        typing: true,
      });
      const reader = await sendChatMessage(requestBody);
      utilService.segmentTrackEvent('Chatbot request sent', requestBody);
      let body = '';
      const decoder = new TextDecoder();
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          const parsed = JSON.parse(body);
          const { responseText, query, queryId, url_list: links } = parsed;
          dispatch({ type: 'updateResponse', responseText, query, queryId, links, typing: false });
          return;
        }
        // Process current incoming chunk of data
        body += decoder.decode(value);
        body = body.replace(/\n/g, '\\n');
        body = body.replace(
          //get rid of control characters
          /[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g,
          ''
        );
        try {
          const parsed = JSON.parse(body);
          const { responseText, query, queryId, url_list: links } = parsed;
          dispatch({ type: 'updateResponse', responseText, query, queryId, links });
        } catch {
          // Because this is a streamed response, `body` will be a malformed JSON until the full body is received.
          // If the above try block fails, we try to coerce it into a properly formed JSON by closing the body prematurely.
          // A bit hacky, but I wasn't able to find a library that did this out of the box.
          // Temporary fix for : INCI-3045: Docs AI chatbot unable to respond to all queries
          if (body.length > 18){
            const parsed =
              body.endsWith(`",`) || body.endsWith(`"`)
                ? JSON.parse(`${body.slice(0, -2)}"}`)
                : JSON.parse(`${body}"}`);
            const { responseText, query, queryId, url_list: links } = parsed;
            dispatch({ type: 'updateResponse', responseText, query, queryId, links });
          } else {
            const parsed = JSON.parse(`${body}"}`);
            const { responseText, query, queryId, url_list: links } = parsed;
            dispatch({ type: 'updateResponse', responseText, query, queryId, links });
          }

        } finally {
          scrollToLast(); // keeps scroll at the bottom as stream is typing
        }
      }
    } catch (err) {
      // Generic error handling
      // Can update in the future to display different texts based on error code
      console.error(err);
      dispatch({
        type: 'updateResponse',
        responseText: '⚠️ I cannot process your question at this time. Please try again later.',
        query: '',
        queryId: '',
        urlList: [],
        typing: false,
      });
    }
  }

  async function submitFeedback(queryId, feedback) {
    const userFeedback = feedback === 'yes' ? 11 : 22; // API is set up to take 11 as upvote and 22 as downvote
    try {
      await sendFeedback({ queryId, userFeedback });
      dispatch({ type: 'newFeedback', queryId, feedback });
    } catch (err) {
      console.error(err);
      return;
    }
  }

  //   Everything in the top bar of the chatbot drawer
  //   Title, icons, etc.
  function renderHeader() {
    return (
      <div className={s.header}>
        <div className={s.title}>
          <SparkleIcon color={Colors.white} />
          <HSpacer />
          Docs AI
          <HSpacer />
        </div>
        <div className={s.icons}>
          <Tooltip
            content="Best Practices & Disclaimer"
            direction="bottomLeft"
            fixOverflow={false}
            className={s.helpTooltip}
          >
            <HelpIcon
              color={Colors.blackLighter6}
              onClick={() => {
                utilService.segmentTrackEvent('Chatbot "Learn more" button clicked');
                utilService.openInNewWindow(
                  'https://www.marqeta.com/developer-guides/assistant-help'
                );
              }}
            />
          </Tooltip>
          {expanded ? (
            <MinimizeIcon onClick={() => setExpanded(!expanded)} color={Colors.blackLighter6} />
          ) : (
            <ExpandIcon onClick={() => setExpanded(!expanded)} color={Colors.blackLighter6} />
          )}
          <button
            className={s.iconContainer}
            onClick={() => {
              setIsOpen(false);
              dispatch({ type: 'clearHistory' }); // Clear chatbot history when close button is clicked
              setQuery('');
            }}
          >
            <Icon type="close" overrideColor={Colors.blackLighter6} noHoverEffects={true} />
          </button>
        </div>
      </div>
    );
  }
  //   All the messages from the user and the chatbot
  function renderChatbotMessages() {
    const displayScrollDownArrow = scrollPosition < 100;
    return (
      <div className={s.chatbotBody} ref={bodyRef} data-testid="chatbot-body">
        {state.messages.map((message, idx) => {
          switch (message.type) {
            case 'query':
              return (
                <div key={idx} className={s.userMessage}>
                  {message.text}
                </div>
              );
            case 'intro':
              return (
                <div className={s.botMessage} key={idx}>
                  <div className={s.botMessageContent}>
                    <p>{message.text}</p>
                  </div>
                </div>
              );
            default:
              return <BotMessage key={idx} message={message} submitFeedback={submitFeedback} />;
          }
        })}
        <div className={s.scrollButtonContainer}>
          {displayScrollButton && (
            <button
              className={`${s.scrollButton} ${
                isScrolling ? s.showScrollButton : s.hideScrollButton
              }`}
              onClick={displayScrollDownArrow ? () => scrollToLast('smooth') : scrollToFirst}
            >
              <Icon
                type={displayScrollDownArrow ? 'arrow-down' : 'arrow-up'}
                noHoverEffects={true}
                overrideColor={Colors.actionColorDarker1}
              />
            </button>
          )}
        </div>
      </div>
    );
  }

  //   Includes the chatbox textarea and everything below it
  function renderFooter() {
    return (
      <div className={s.footer}>
        <form
          className={s.form}
          onSubmit={submitForm}
          onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && submitForm(e)}
        >
          <Textarea
            id="query-textarea"
            name="query"
            placeholder="Ask me about Marqeta products and APIs"
            noResize={true}
            value={query}
            onChange={(e) => {
              utilService.resizeTextarea(e.target, 240);
              setQuery(e.target.value);
            }}
            disabled={state.loading}
            rows={2}
            testId="chatbot-textarea"
          />
          <SendMessageButton onClick={submitForm} loading={state.loading} />
        </form>
        <VSpacer />
        <Text type="footnote">
          Use of this assistant is subject to Marqeta's{' '}
          <a href="https://www.marqeta.com/api-terms" target="_blank">
            API Terms of Use
          </a>{' '}
          and OpenAI's{' '}
          <a href="https://openai.com/policies" target="_blank">
            Terms and Policies
          </a>
          . Conversations may be monitored and retained.{' '}
          <a href="https://www.marqeta.com/developer-guides/assistant-help" target="_blank">
            Learn more
          </a>
          .
        </Text>
      </div>
    );
  }

  return (
    <>
      <OpenChatbotButton onClick={setIsOpen} />
      <Drawer
        isOpened={isOpen}
        orientation="right"
        backdrop={false}
        keepOpen={true}
        closeDrawer={() => setIsOpen(false)}
        dynamicWidth={expanded}
        testId="chatbot-drawer"
        header={renderHeader()}
        showCloseButton={false}
        footer={renderFooter()}
      >
        {renderChatbotMessages()}
      </Drawer>
    </>
  );
};

const BotMessage = ({ message, submitFeedback }) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [copiedMessageTimeoutId, setCopiedMessageTimeoutId] = useState(0);
  const { text, links = [], queryId } = message;
  const showCopyButton = !!queryId;
  return (
    <div className={s.botMessage}>
      <div className={`${s.botMessageContent} ${showCopyButton && s.hasCopyButton}`}>
        <RenderedContent html={text} />
        {showCopyButton && (
          <button
            className={`${s.copyIcon} ${showCopiedMessage && s.isCopied}`}
            onClick={() => {
              utilService.copyToClipboard(text);
              clearTimeout(copiedMessageTimeoutId);
              setShowCopiedMessage(true);
              setCopiedMessageTimeoutId(setTimeout(() => setShowCopiedMessage(false), 2000));
            }}
          >
            <Tooltip
              direction="bottom"
              fixOverflow={false}
              content={showCopiedMessage ? 'Copied!' : 'Copy'}
            >
              <CopyIcon />
            </Tooltip>
          </button>
        )}
      </div>
      <Links urls={links} />
      <FeedbackButtons message={message} onClick={submitFeedback} />
    </div>
  );
};

// Source links at the bottom of each bot response
const Links = ({ urls = [] }) => {
  if (!urls.length) return null;
  return (
    <div className={s.urlList}>
      <Text type="footnote">Sources:</Text>
      {urls.map((slug, idx) => {
        const url = `https://www.marqeta.com${slug}` // slug will be in the form of '/docs/developer-guides/page-title'
        const urlText =
          url
            .split('/')
            .pop()
            .replace(/-/g, ' ')
            .split(' ')
            .map((word) =>
              // hacky fix to capitalize words from the URL that should be all caps
              // in the next version I'd like to see if the backend can return properly formatted page names instead of URLs
              ['api', 'jit', 'sdk', 'mq', 'eu', 'dwt', 'pci', 'gpa', 'ach', 'mcc'].includes(word)
                ? word.toUpperCase()
                : { diva: 'DiVA', sdks: 'SDKs', apis: 'APIs', dwts: 'DWTs', via: 'via', in: 'in' }[
                    word
                  ] || word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join(' ') || '';
        return (
          <Text
            type="footnote"
            key={idx}
            className={s.url}
            onClick={() => {
              utilService.segmentTrackEvent('Chatbot source link clicked', { url });
              utilService.openInNewWindow(url);
            }}
          >
            <Tooltip direction="top" fixOverflow={true} content={urlText}>
              <Link to="">{idx + 1}</Link>
            </Tooltip>
          </Text>
        );
      })}
    </div>
  );
};

const FeedbackButtons = ({ message, onClick }) => {
  const { query, queryId, userFeedback } = message;
  if (!queryId) return null;
  const feedbackText =
    userFeedback === 'yes'
      ? 'Great!'
      : userFeedback === 'no'
      ? 'Sorry about that!'
      : 'Was this helpful?';
  return (
    <div className={s.botMessageFooter}>
      <Text type="footnote">{feedbackText}</Text>
      <FeedbackIcon
        type="thumbsUp"
        selected={userFeedback === 'yes'}
        onClick={() => {
          utilService.segmentTrackEvent('Chatbot feedback submitted', {
            query,
            queryId,
            feedback: 'yes',
          });
          onClick(queryId, 'yes');
        }}
      />
      <FeedbackIcon
        type="thumbsDown"
        selected={userFeedback === 'no'}
        onClick={() => {
          utilService.segmentTrackEvent('Chatbot feedback submitted', {
            query,
            queryId,
            feedback: 'no',
          });
          onClick(queryId, 'no');
        }}
      />
    </div>
  );
};
const OpenChatbotButton = ({ onClick }) => (
  <Button
    type="secondary"
    testId="chatbot-button"
    className={s.chatbotButton}
    onClick={() => {
      utilService.segmentTrackEvent('Chatbot opened');
      onClick(true);
    }}
  >
    <SparkleIcon factor={0.5} /> <HSpacer factor={0.5} /> Ask Docs AI
  </Button>
);

const SendMessageButton = ({ onClick, loading = false }) => (
  <button
    className={`${s.sendIcon} ${loading && s.disabled}`}
    onClick={onClick}
    type="submit"
    data-testid="chatbot-submit-button"
  >
    <SendMessageIcon loading={loading} />
  </button>
);

// Data coming back from the chatbot needs to be processed as HTML.
// For certain node types (ex. codeblocks) we want to replace them with our custom components.
// This is also what we use to render regular docs pages from asciidoc markdown!
const RenderedContent = ({ html }) => {
  const showDots = !html;
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions();
  const processingInstructions = [
    {
      // Custom codeblock processing
      replaceChildren: false,
      shouldProcessNode: function (node) {
        return node.parent && node.parent.name && node.parent.name === 'pre';
      },
      processNode: function (node, children) {
        const { data } = node.children[0] || {};
        return (
          <CodeBlock
            showLineNumbers={false}
            codeWrap={true}
            content={[
              {
                language: 'JSON',
                content: data,
              },
            ]}
          />
        );
      },
    },
    {
      // Inline code
      replaceChildren: false,
      shouldProcessNode: function (node) {
        return node.name === 'code';
      },
      processNode: function (node, children, index) {
        if (!children.length) return;
        return (
          <Text type="code" inline={true}>
            {children[0]}
          </Text>
        );
      },
    },
    {
      // Make links open in a new tab
      replaceChildren: false,
      shouldProcessNode: function (node) {
        return node.parent && node.parent.name && node.parent.name === 'a';
      },
      processNode: function (node, children, index) {
        return (
          <a href={node.parent.attribs.href} target="_blank" rel="noreferrer">
            {node.data}
          </a>
        );
      },
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];
  const sanitizedHtml = DOMPurify.sanitize(marked.parse(html || ''));
  const isValidNode = () => true;
  const parsedHtml = new Parser().parseWithInstructions(
    sanitizedHtml,
    isValidNode,
    processingInstructions
  );
  return (
    <div>
      <span>{parsedHtml}</span>{' '}
      {showDots && <img src={TypingGIF} style={{ display: 'inline', margin: 0 }} />}
    </div>
  );
};

export default Chatbot;

import React from 'react';
import { activeLink, landingCellWrap, internalLink } from '../../styles/Base.module.css';
import * as s from '../home_header_widget/HomeHeaderWidget.module.css';
import { Text, HSpacer, VSpacer, Icon, NumberedList } from '@mqd/volt-base';
import { Link } from 'gatsby';
import { utilService } from '../../services';

export default function GettingStartedSteps({ steps }) {
  const stepData = utilService.jsonToFormattedArray(steps);
  return (
    <div className={s.landingSectionContainer} data-testid="getting-started-steps">
      <div className={s.flexContainer}>
        {renderFourSteps(stepData)}
        {renderAPIExplorerText()}
      </div>
    </div>
  );
}

const renderFourSteps = (steps) => {
  return (
    <div className={s.fourStepsContainer}>
      <Text type="h2">Get started in four steps</Text>
      <div className={`${landingCellWrap} ${s.numberedListWrap}`}>
        <NumberedList>
          {steps.map(({ link, description }) => (
            <NumberedList.ListItem>
              <Link to={link} className={`${activeLink} ${s.numberedListLink}`}>
                <Text className={s.linkText} type="h6" inline={true}>
                  {description}
                </Text>
                <HSpacer factor={0.5} inline={true} />
                <Icon type="arrow-right" mod="default" factor={1} noHoverEffects={true} />
              </Link>
            </NumberedList.ListItem>
          ))}
        </NumberedList>
      </div>
    </div>
  );
};

const renderAPIExplorerText = () => {
  return (
    <div className={s.apiExplorerContainer}>
      <Text type="h2">API Explorer</Text>
      <div className={landingCellWrap}>
        <Text type="body-sm">
          You can leverage our API Explorer to access and make calls to all Core API endpoints right
          from your browser. Start prototyping your application straight from our docs.
        </Text>
        <VSpacer factor={2} />
        <Link to="/core-api-explorer" className={internalLink}>
          Go to API Explorer
        </Link>
      </div>
    </div>
  );
};

import React from 'react';
import * as s from './HomeHeaderWidget.module.css';
import { Text } from '@mqd/volt-base';
import HeaderImage from '../../../static/header.svg';

function HomeHeaderWidget({ title, description }) {
  const renderHeaderText = () => {
    return (
      <div className={s.headerTextHomeContainer}>
        <Text type="h1" className={s.headerText}>
          {title}
        </Text>
        <Text type="body-lg" inline={false}>
          {description}
        </Text>
      </div>
    );
  };

  return (
    <div data-testid="home-header">
      <div className={s.headerImg}>
        <img src={HeaderImage} data-testid="home-header-svg" />
      </div>
      {renderHeaderText()}
    </div>
  );
}

export default HomeHeaderWidget;

import React, { useState, useEffect } from 'react';
import { Colors, Icon, Text, HSpacer } from '@mqd/volt-base';
import { SidenavIcon } from '../';
import { Link } from 'gatsby';
import { utilService } from '../../services';
import { pageDataStore } from '../../stores';
import * as s from './SidenavAccordion.module.css';

// This component is largely copied over from the Volt Accordion component,
// with styling changes made in the SidenavAccordion.module.css file
function SidenavAccordion({
  sections = [],
  width,
  activeTab = null,
  activeCategory = null,
  hasIcons = false,
  testId,
}) {
  const [expandedSections, setExpandedSections] = useState([]);

  // When a section is clicked, expand it without collapsing other expanded sections
  useEffect(() => {
    if (!expandedSections.includes(activeCategory))
      setExpandedSections([...expandedSections, activeCategory]);
  }, [activeCategory]);

  // Expand the relevant sections when the user switches to a new Collection tab
  useEffect(() => {
    setExpandedSections(
      sections.reduce((expandedSections, section) => {
        const { defaultExpanded, key, title } = section;
        if (defaultExpanded) {
          // If a key is specified, capture that; otherwise, use the title
          const sectionIdentifier = key || title;
          expandedSections.push(sectionIdentifier);
        }
        return expandedSections;
      }, [])
    );
  }, [activeTab]);

  const toggleSectionExpansion = (sectionKey, sectionTitle) => {
    const updatedExpandedSections = expandedSections.slice();
    const sectionIdentifier = sectionKey || sectionTitle;
    const indexOfId = updatedExpandedSections.indexOf(sectionIdentifier);
    if (indexOfId !== -1) updatedExpandedSections.splice(indexOfId, 1);
    else updatedExpandedSections.push(sectionIdentifier);
    setExpandedSections(updatedExpandedSections);
  };

  const renderSection = (section, idx) => {
    const { content = [], title = '', key } = section;
    const sectionIdentifier = key || title;
    const isExpanded =
      expandedSections.includes(sectionIdentifier) && title !== 'Home' ? s.isExpanded : null;
    return (
      <div className={s.section} key={key || idx}>
        {renderSectionTitle(title, isExpanded, key, section)}
        {renderSectionContent(content, isExpanded)}
      </div>
    );
  };

  const renderSectionTitle = (title, isExpanded, key, section) => {
    if (title === 'Home') {
      const urlPath = section.content.props.links[0].urlPath;
      const activePage = pageDataStore.pagePath;
      const isActive = activePage === urlPath;

      return (
        <Link to={urlPath} className={`${s.sectionTitle} ${isActive ? s.active : ''}`}>
          <div className={`${s.sectionTitleIcon}`}>
            <SidenavIcon type="home" color={isActive ? Colors.actionColor : Colors.blackLighter5} />
          </div>
          <HSpacer factor={1.5} />
          <Text type="h6" color={isActive ? Colors.actionColor : ''}>
            {title}
          </Text>
        </Link>
      );
    }
    // Guides title with icons
    if (hasIcons) {
      const isActive = activeCategory === key;
      const iconName = utilService.slugify(title);

      return (
        <div
          className={`${s.sectionTitle} ${isExpanded}`}
          onClick={() => toggleSectionExpansion(key, title)}
          data-testid="sidenav-accordion-icons"
        >
          <div className={`${s.sectionTitleIcon} ${isExpanded}`} data-testid="sidenav-icon">
            <SidenavIcon
              type={iconName}
              color={isActive ? Colors.actionColor : Colors.blackLighter5}
            />
          </div>
          <HSpacer factor={1.5} />
          <Text type="h6" color={isActive ? Colors.actionColor : ''}>
            {title}
          </Text>
        </div>
      );
    }
    // Regular title for non-Guides pages
    else {
      return (
        <div
          className={`${s.sectionTitle} ${isExpanded}`}
          onClick={() => toggleSectionExpansion(key, title)}
          data-testid="sidenav-accordion-no-icons"
        >
          <div className={`${s.sectionTitleIcon} ${isExpanded}`}>
            <Icon type="caret-right" noHoverEffects={true} />
          </div>
          <HSpacer factor={0.5} />
          <Text type="h6">{title}</Text>
        </div>
      );
    }
  };

  const renderSectionContent = (content, isExpanded) => {
    if (!isExpanded) return null;
    return (
      <div className={`${s.sectionContent} ${isExpanded}`} data-testid="expanded-content">
        {content}
      </div>
    );
  };

  return (
    <div className={s.container} style={{ width: width }} data-testid={testId}>
      <div className={s.sections}>
        {sections.map((section, idx) => renderSection(section, idx))}
      </div>
    </div>
  );
}

export default SidenavAccordion;

// extracted by mini-css-extract-plugin
export var activeLink = "Base-module--activeLink--bccb3";
export var backToMarqetaLink = "Base-module--backToMarqetaLink--7a41e";
export var backToMarqetaText = "Base-module--backToMarqetaText--969b0";
export var blue = "Base-module--blue--15aef";
export var desktop = "Base-module--desktop--b5e8a";
export var divider = "Base-module--divider--eda75";
export var internalLink = "Base-module--internalLink--bf15b";
export var landingCellWrap = "Base-module--landingCellWrap--843b5";
export var landingLinkContainer = "Base-module--landingLinkContainer--b676e";
export var landingLinkText = "Base-module--landingLinkText--b6d8d";
export var landingSectionContainer = "Base-module--landingSectionContainer--b74a4";
export var landingSubtitle = "Base-module--landingSubtitle--78c35";
export var mobile = "Base-module--mobile--19852";
const chatbotUrl = process.env.GATSBY_CHATBOT_URL;

/**
 * Posts a query to the Chatbot service
 * @param {object} requestBody - Request body object containing query
 * @returns {object} - Reader object containing response stream
 */
const sendChatMessage = async (requestBody) => {
  requestBody = requestBody || {};
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(chatbotUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    });

    const { body, status, ok } = response;
    if (!ok || status >= 400) throw Error();
    const reader = body.getReader();
    return reader;
  } catch (err) {
    console.error(err);
    throw Error();
  }
};

/**
 * Sends a PATCH request to update the query with the user's feedback
 * @param {String} queryId - ID of the query to leave feedback on
 * @param {Number} userFeedback - 11 if positive, 22 if negative
 * @returns {object} - Reader object containing response stream
 */
const sendFeedback = async ({ queryId, userFeedback }) => {
  const requestBody = { queryId, userFeedback };
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(chatbotUrl, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(requestBody),
    });
    const { body, status, ok } = response;
    if (!ok || status >= 400) throw Error();
    const reader = body.getReader();
    return reader;
  } catch (err) {
    console.error(err);
    throw Error();
  }
};

export { sendChatMessage, sendFeedback };

import { action, decorate, runInAction, observable, computed, configure } from 'mobx';
import axios from 'axios';
import { gqlApi } from '../services';
import { globalAlertStore } from './';
import { AuthorizationService, SandboxService, utilService } from '../services';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class UserStore {
  // observables
  info = {};
  sessionInfo;
  loggedIn = null; // boolean or null (log in not checked yet)

  // static variables
  ssoURL = process.env.GATSBY_SSO_LOGIN_URL;
  marqetaURL = process.env.GATSBY_MARQETA_URL;
  tokenUrl = `${this.ssoURL}/redsea/oauth2/token`;
  redseaLogoutUrl = `${this.ssoURL}/redsea/logout`;
  redseaSessionInfo = `${this.ssoURL}/redsea/sessioninfo`;
  redseaSessionDurationTime = 15 * 60;
  lastRedseaSession;

  get userInfo() {
    return this.info && this.info.info;
  }

  get sandboxInfo() {
    return this.info && this.info.auth;
  }

  get sandboxJobState() {
    return this.info && this.info.sandbox && this.info.sandbox.job_state;
  }

  // Hides sandbox banner on get sandbox job state failure
  // or when sandbox job_state is completed.
  get hideSandboxWidget() {
    try {
      return this.info.sandbox.job_state === 'completed';
    } catch {
      return true;
    }
  }

  setTimeoutWarning() {
    globalAlertStore.addAlert('sessionTimeoutWarning', 'header');
  }

  setTimedoutNotice() {
    globalAlertStore.addAlert('sessionTimedoutNotice', 'header');
    globalAlertStore.dismissAlert('sessionTimeoutWarning', 'header');
    this.logOut();
  }

  // state is optional, it is used to store the path the app will load on login.
  logIn(state) {
    const authorizationBaseUrl = `${this.ssoURL}/redsea/oauth2/authorize`;
    const redirectState = typeof state === 'string' ? state : window.location.href;

    const params = AuthorizationService.generateOauthParams(redirectState);
    const search = AuthorizationService.convertObjectToSearch(params);
    window.location.href = authorizationBaseUrl + search;
  }

  async logOut() {
    this.loggedIn = false;
    this.info = {};

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const headers = {
        authorization: `Bearer ${accessToken}`,
      };
      try {
        await axios.post(this.redseaLogoutUrl, '', { headers });
      } catch (err) {
        console.error('Failed to log out from Redsea', err);
      }
    }
    localStorage.removeItem('accessToken');

    // Redirect user to sso signout url
    const params = AuthorizationService.generateOauthParams(window.location.href);
    const search = AuthorizationService.convertObjectToSearch(params);

    // Segment analytics call to reset analytics.identify() on the client-side
    // https://segment.com/docs/getting-started/04-full-install/#using-analyticsreset
    window.analytics && window.analytics.reset();

    window.location.href = `${this.ssoURL}/signout${search}`;
  }

  signUp() {
    window.location = `${this.ssoURL}/create-account?r=${encodeURIComponent(window.location.href)}`;
  }

  async updateUserInfo() {
    try {
      const userInfo = await SandboxService.getUserSandboxData();
      runInAction(() => {
        this.info = userInfo.data || {};
      });
    } catch (err) {
      console.error(err);
    }
  }

  async checkUserLogin() {
    // Note: When this is called, the user's session is renewed at Marqeta.com as well if signed in
    try {
      // check if accessToken exists first before making GraphQL call
      const userInfo = localStorage.getItem('accessToken')
        ? await SandboxService.getUserSandboxData()
        : {};
      runInAction(() => {
        this.info = userInfo.data || {};
        if (userInfo.data && userInfo.data.info) {
          // User login init
          this.loggedIn = true;
          this.recordLastActivity();
          this.setupActivityListeners();
          this.recordRedseaSession();

          globalAlertStore.dismissAlert('sessionTimeoutWarning', 'header');
          globalAlertStore.dismissAlert('sessionTimedoutNotice', 'header');
          this._setSessionTimer(); // tracks session time
        } else {
          this.loggedIn = false;
          clearInterval(this.activityInterval); // clear session timer if it was instantiated
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  updateSandboxStatus(sandboxJobState) {
    this.info.sandbox = { job_state: sandboxJobState };
  }

  hoursToSeconds(hours) {
    return hours * 60 * 60;
  }

  minutesToSeconds(min) {
    return min * 60;
  }

  async _setSessionTimer() {
    // This is only called when the user is confirmed to be logged in
    clearInterval(this.activityInterval);
    const intervalTime = 60; // seconds between calls for last activity time

    this.activityInterval = setInterval(async () => {
      try {
        const timeoutTime = this.hoursToSeconds(3) - intervalTime; // 3 hours until the page will timeout
        const warningTime = timeoutTime - this.minutesToSeconds(10); // 10 minute before the timeout, user is given a session warning

        // Convert cookie string back to date object
        const lastActivity = new Date(this.getLastActivity());
        const currTime = new Date();
        const elapsedTime = (currTime - lastActivity) / 1000; // unix time in seconds;
        const elapsedRedseaTime = (currTime - this.lastRedseaSession) / 1000;

        // How this works:

        //  0     10    20    30    40   ...   360   370 (minutes)
        //  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |
        //        *       *                     *     *
        //(user activity)(now)             (warning) (3 hour timeout)

        // 1) At 10 minutes there is user activity and the time at which this occurs is stored in a cookie
        // 2) 23 minutes is the time "now". The elapsed time is 13 minutes.
        //      If the elapsed time > (15 min - interval time), we want to keep the session alive via refreshSession()
        // 3) Warning will show after 350 minutes since the last user activity.
        // 4) Session will timeout and log out the user at 3 hours of no activity.

        if (elapsedRedseaTime > this.redseaSessionDurationTime - intervalTime) {
          // See bullet point 2
          this.runRedseaTokenCheck();
        } else if (elapsedTime >= warningTime && elapsedTime < warningTime + intervalTime) {
          // The elapsedTime is greater/equal to warningTime, but less than warningTime plus intervalTime.
          // This prevents unnecessary further event dispatching.  See bullet point 3
          this.setTimeoutWarning();
        } else if (elapsedTime > timeoutTime) {
          // User has timed out. See bullet point 4
          this.setTimedoutNotice();
        }

        // Checks to see if previous job state has changed
        const previousJobState = this.sandboxJobState;
        const currentUserInfo = await SandboxService.getUserSandboxState();
        const currentJobState =
          currentUserInfo && currentUserInfo.data ? currentUserInfo.data.job_state : null;

        // If job state changes to 'completed', update with user auth info
        if (previousJobState !== 'completed' && currentJobState === 'completed') {
          this.updateUserInfo();
        } else if (previousJobState !== currentJobState) {
          this.updateSandboxStatus(currentJobState);
        }

        if (!currentUserInfo.data.info) {
          this.logOut();
        }
      } catch (err) {
        console.error(err); // TO DO: change to internal logging
      }
    }, intervalTime * 1000);
  }

  // New activity functions borrowed from AMC

  // run token check to see if session is expired, if so it will log out
  async runRedseaTokenCheck() {
    try {
      const result = await this.pingSession();
      if (!result) {
        this.setTimedoutNotice();
        this.logOut();
      }
      // sucessful result, restart timer
      this.recordRedseaSession();
    } catch (error) {
      // unsucessful result log user out
      this.logOut();
    }
  }

  async pingSession() {
    // If this succeeds it will restart the 15 min session timer
    return await gqlApi.gqlQuery(
      `query currentUser {
        currentUser {
          token
          first_name
          last_name
          phone
          email
          customer_token
          ttl_in_seconds
          active
          password_updated_time
          failed_login_attempts
          last_failed_login_attempt_time
          last_signed_in_time
          last_signed_in_ip_address
          email_verified_time
          email_opt_in
          terms_of_service
          hub_spot_sync_id
          mfa_enabled
          mfa_totp_enabled
          mfa_totp_revealed
          mfa_last_challenge_time
          device_token
          scopes
          roles {
            domain_type
            domain_id
            role_token
          }
          created_time
          last_modified_time
        }
      }
      `
    );
  }

  recordRedseaSession() {
    this.lastRedseaSession = new Date();
  }

  setupActivityListeners() {
    const recordLastActivityHandler = () => this.recordLastActivity();
    window.addEventListener('click', recordLastActivityHandler);
    const debouncedHandler = utilService.debounced(500, recordLastActivityHandler);
    window.addEventListener('scroll', debouncedHandler);
  }

  recordLastActivity() {
    const activityDate = new Date();
    utilService.setCookie({
      name: 'lastAppActivity',
      value: activityDate,
      days: 265,
    });
  }

  getLastActivity() {
    return utilService.getCookie('lastAppActivity');
  }

  secondsSinceLastActivity() {
    const lastAppActivity = this.getLastActivity();
    if (!lastAppActivity) {
      return null;
    }
    try {
      const lastActivityDate = new Date(lastAppActivity);
      return (new Date() - lastActivityDate) / 1000;
    } catch (error) {
      return null;
    }
  }
}

decorate(UserStore, {
  // values
  info: observable,
  sessionInfo: observable,
  loggedIn: observable,

  //actions
  pingSession: action.bound,
  logIn: action.bound,
  logOut: action.bound,
  signUp: action.bound,
  checkUserLogin: action.bound,
  updateSandboxStatus: action.bound,

  // computed
  userInfo: computed,
  sandboxInfo: computed,
  hideSandboxWidget: computed,
  sandboxJobState: computed,
});

const userStore = new UserStore();
export default userStore;

import React from 'react';
import { Text } from '@mqd/volt-base';
import { backToMarqetaLink, backToMarqetaText } from '../../styles/Base.module.css';

function BackToMarqeta() {
  return (
    <div className={backToMarqetaLink}>
      <a href="https://www.marqeta.com">
        <Text type="footnote" className={backToMarqetaText}>
          Back to Marqeta.com
        </Text>
      </a>
    </div>
  );
}

export default BackToMarqeta;

import React from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { useImages } from '../image/useImages';

const InlineImage = ({ imgName, alt, width }) => {
  const allImages = useImages();
  const image = allImages.edges.find((edge) => edge.node.name === imgName);
  if (!image) {
    console.error(`Could not find image with name ${imgName}.`);
    return <img alt={alt} />;
  }
  const style = {
    maxWidth: Number(width) || '100%',
    display: 'inline',
  };
  const { node } = image;
  const imageSrc = node.extension === 'svg' ? node.publicURL : getSrc(node);
  return <img src={imageSrc} alt={alt} title={alt} style={style} data-testid="inline-image" />;
};

export default React.memo(InlineImage);

import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Input } from '@mqd/volt-base';
import { eventsStore } from '../../stores';
import * as s from './SearchForm.module.css';

class SearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.persistInputValue = this.persistInputValue.bind(this);
  }

  // Autofocus search input on desktop
  componentDidMount() {
    setTimeout(() => {
      // delay in order to call method after other onLoad events and after Cludo sets search box val
      if (!eventsStore.mobileMode) {
        this.persistInputValue();
        document.querySelector(`#${this.props.id} input`) &&
          document.querySelector(`#${this.props.id} input`).focus();
      }
    });
  }

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  persistInputValue() {
    const value =
      document.querySelector(`#${this.props.id} input`) &&
      document.querySelector(`#${this.props.id} input`).value;
    this.setState({ value: value });
  }

  render() {
    return (
      <form id={this.props.id} className={s.form} autoComplete="off" style={{ width: '100%' }}>
        <Input
          type="search"
          placeholder={'Search documentation…'}
          onChange={this.handleChange}
          value={this.state.value}
          leftIcon="search"
          width={this.props.width}
          onBlur={this.persistInputValue}
          testId={`${this.props.testId}-input`}
        />
      </form>
    );
  }
}

SearchForm.propTypes = {
  id: PropTypes.string,
  width: PropTypes.string,
};

export default observer(SearchForm);

import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { Breadcrumbs } from '@mqd/volt-base';
import TableCell from './TableCell';
import * as s from './Table.module.css';

const Table = ({ rows, parent }) => {
  const [definitions, setDefinitions] = useState({});
  const [breadcrumbTitles, setBreadcrumbTitles] = useState(parent);
  const handleObjectClick = (value, parent) => {
    setDefinitions(value);
    setBreadcrumbTitles(parent);
  };

  useEffect(() => {
    setDefinitions(pathOr({}, parent, rows));
    setBreadcrumbTitles(parent);
  }, [rows]);

  if (!definitions) return;

  const crumbs = breadcrumbTitles.map((title, idx) => ({
    children: title,
    onClick: (e) => {
      e.preventDefault();
      const newBreadcrumbs = breadcrumbTitles.slice(0, idx + 1);
      const newPath = newBreadcrumbs
        .map((name, index) => (index > 0 ? [name, 'children'] : [name]))
        .reduce((a, b) => a.concat(b));
      setBreadcrumbTitles(newBreadcrumbs);
      setDefinitions(pathOr({}, newPath, rows));
    },
  }));

  return (
    <div className={s.wrap}>
      <table className={s.table} data-testid="api-widget-table">
        <tbody>
          <tr key="breadcrumbs">
            <td colSpan="5">
              {crumbs.length > 1 ? (
                <Breadcrumbs crumbs={crumbs} />
              ) : (
                <div className={s.breadcrumbs}>{breadcrumbTitles[0]}</div>
              )}
            </td>
          </tr>
          {Object.values(definitions).map((row, index) => {
            return (
              <tr key={index}>
                {Object.keys(row).map((key, index) => {
                  return (
                    <TableCell
                      key={index}
                      cellType={key}
                      value={row[key]}
                      parent={row.parent}
                      clickHandler={handleObjectClick}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(Table);

import { globalAlertStore } from '../../stores';
import { observer } from 'mobx-react';

// to be included ONLY in the header component
const HeaderAlert = () => globalAlertStore.latestHeaderAlert;

// can be included anywhere, since it's fixed
const FooterAlert = () => globalAlertStore.latestFooterAlert;

export default {
  header: observer(HeaderAlert),
  footer: observer(FooterAlert),
};

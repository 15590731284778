import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { ApiWidget } from '../';
import * as s from './EndpointWidget.module.css';
import RenderSwaggerBanner from '../banner_alert/SwaggerBanners';

class EndpointWidget extends Component {
  render() {
    const { domId, api, endpoint, method, spec } = this.props;
    if (!spec) {
      return null;
    }
    return (
      <div className={s.ctaBanner}>
        <RenderSwaggerBanner
          defaultStateBanner={
            <ApiWidget testId={domId} endpoint={endpoint} method={method} specName={spec} />
          }
        />
      </div>
    );
  }
}

export default observer(EndpointWidget);

import React from 'react';
import * as s from './HomeMoreResourcesWidget.module.css';
import { activeLink } from '../../styles/Base.module.css';
import { Col, Row, Text, Link as VoltLink, VSpacer } from '@mqd/volt-base';
import { utilService } from '../../services';

// import icons
const iconSVGs = require.context('./icons', true, /\.svg$/);
const moreResourcesIcons = iconSVGs.keys().reduce((images, path) => {
  const key = path.substring(path.lastIndexOf('/') + 1);
  images[key] = iconSVGs(path).default;
  return images;
}, {});

function HomeMoreResourcesWidget({ data }) {
  const moreResourcesArray = utilService.jsonToFormattedArray(data);
  const renderMoreResourcesSection = ({ icon, alt = '', header, description, link, linktext }) => {
    return (
      <>
        <img src={moreResourcesIcons[icon]} alt={alt} />
        <VSpacer factor={1} />
        <Text type="h3" inline={false}>
          {header}
        </Text>
        <Text type="body-sm" inline={false}>
          {description}
        </Text>
        <VSpacer factor={1} />
        <VoltLink href={link} className={activeLink} external={true} iconType="new-tab">
          {linktext}
        </VoltLink>
      </>
    );
  };

  return (
    <div data-testid="more-resources-section">
      <Row className={`${s.desktop} ${s.container}`}>
        {moreResourcesArray.map((section, idx) => (
          <Col key={idx} span={6}>
            {renderMoreResourcesSection(section)}
          </Col>
        ))}
      </Row>
      <Row className={`${s.mobile} ${s.container}`}>
        {moreResourcesArray.map((section, idx) => (
          <Col key={idx} span={12} className={s.section}>
            {renderMoreResourcesSection(section)}
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default HomeMoreResourcesWidget;

// This code was taken from the wam/apps/amc/root/src/apis/GqlApi.js file
import { decorate, computed, observable, action } from 'mobx';
import gql from 'graphql-tag';
import ApolloClient from 'apollo-boost';

class GqlApi {
  async gqlMutation(mutation, variables, fetchPolicy = 'no-cache', rethrowError = false) {
    mutation = gql`
      ${mutation}
    `;
    if (!this.gqlClient) {
      throw new Error(
        `No gqlClient defined for ${this.constructor ? this.constructor.name : 'Unknown Class'}`
      );
    }
    try {
      return await this.gqlClient.mutate({
        mutation,
        variables,
        fetchPolicy,
      });
    } catch (e) {
      console.error(e);
      if (rethrowError) {
        throw new Error(e);
      }
    }
  }

  // query: string, variables: Object, fragements [],
  async gqlQuery(query, variables, fetchPolicy = 'no-cache') {
    query = gql`
      ${query}
    `;
    if (!this.gqlClient) {
      throw new Error(
        `No gqlClient defined for ${this.constructor ? this.constructor.name : 'Unknown Class'}`
      );
    }
    try {
      return await this.gqlClient.query({
        query,
        variables,
        fetchPolicy,
      });
    } catch (e) {
      console.error(e);
    }
  }

  get gqlClient() {
    return new ApolloClient({
      uri: '/graphql',
      request: (operation) => {
        const headers = {
          'x-marqeta-access-token-type': 'redsea',
        };
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          headers.authorization = `Bearer ${accessToken}`;
        }
        operation.setContext({ headers });
      },
      credentials: 'include',
    });
  }
}

decorate(GqlApi, {
  currentGqlClient: observable,
  gqlQuery: action.bound,
  gqlMutation: action.bound,
  gqlClient: computed,
});
const api = new GqlApi();
export default api;

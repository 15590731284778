import React, { useState } from 'react';
import { Button, Input, Modal, Textarea, VSpacer } from '@mqd/volt-base';
import { globalToastStore, userStore } from '../../../stores';
import { utilService, feedbackService } from '../../../services';
import { observer } from 'mobx-react';

const FeedbackModal = ({ hideModal, options }) => {
  const userEmail = userStore.userInfo?.email;
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState(userEmail || '');
  const [feedbackError, setFeedbackError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [pending, setPending] = useState(false);

  const updateFeedback = (val) => {
    setFeedback(val);
    if (feedbackError && val !== '') {
      setFeedbackError('');
    }
  };

  const validateFeedback = () => {
    if (feedback.trim() === '') {
      setFeedbackError('Feedback is required.');
      return false;
    } else {
      setFeedbackError('');
      return true;
    }
  };

  const validateEmail = () => {
    if (!isValidEmail(email)) {
      setEmailError('Enter a valid email address.');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };

  const isValidEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) || email === '';
  };

  const updateEmail = (val) => {
    setEmail(val);
    if (emailError && isValidEmail(val)) {
      setEmailError('');
    }
  };

  const handleSubmit = () => {
    if (!(validateFeedback() && validateEmail())) {
      return;
    }
    setPending(true);
    const { feedbackId, type, helpfulBool } = options || {};

    const successCB = () => {
      setPending(false);
      hideModal();
      const segmentInfo = feedbackId
        ? {
            element_id: feedbackId,
            type: 'helpful',
            helpful: false,
            comment: feedback,
            element_type: type,
          }
        : {
            comment: feedback,
            type: 'page',
          };
      // submit to segment
      utilService.segmentTrackEvent('Feedback Submitted', segmentInfo);
      // display confirmation toast
      globalToastStore.setToast('feedbackSubmitted', 3);
    };
    const failCB = () => {
      setPending(false);
    };
    feedbackService.submit({ feedback, email, feedbackId, type, helpfulBool }, successCB, failCB);
  };

  return (
    <div>
      <Modal
        type="lg"
        heading="What can we do better?"
        hideModal={hideModal}
        hideModalButtonText="Cancel"
        footerButtons={[
          <Button onClick={handleSubmit} disabled={pending}>
            Submit
          </Button>,
        ]}
      >
        <Textarea
          name="feedback"
          label="Your feedback"
          rows={2}
          noResize={true}
          value={feedback}
          onChange={(e) => updateFeedback(e.target.value)}
          onBlur={validateFeedback}
          error={feedbackError}
        />
        <VSpacer factor={3} />
        <Input
          name="email"
          label="Email"
          labelOptional={true}
          value={email}
          onChange={(e) => updateEmail(e.target.value)}
          onBlur={validateEmail}
          error={emailError}
        />
      </Modal>
    </div>
  );
};

export default observer(FeedbackModal);

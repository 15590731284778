// extracted by mini-css-extract-plugin
export var childrenContainer = "ReleaseNotesWidget-module--childrenContainer--11cdb";
export var dateText = "ReleaseNotesWidget-module--dateText--4322f";
export var descriptionText = "ReleaseNotesWidget-module--descriptionText--5fcf9";
export var flexContainer = "ReleaseNotesWidget-module--flexContainer--06a60";
export var linkText = "ReleaseNotesWidget-module--linkText--6bd88";
export var nodeConnector = "ReleaseNotesWidget-module--nodeConnector--2c9de";
export var nodeConnectorWrapper = "ReleaseNotesWidget-module--nodeConnectorWrapper--c250b";
export var nodeContainer = "ReleaseNotesWidget-module--nodeContainer--26359";
export var nodeLine = "ReleaseNotesWidget-module--nodeLine--379a6";
export var nodePoint = "ReleaseNotesWidget-module--nodePoint--4a272";
export var numberedListLink = "ReleaseNotesWidget-module--numberedListLink--193c2";
export var releaseNoteCell = "ReleaseNotesWidget-module--releaseNoteCell--78152";
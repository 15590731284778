import React, { useState } from 'react';
import { Icon } from '@mqd/volt-base';
import * as s from './CookieNotice.module.css';

const CookieNotice = ({ dismiss, children, testId = 'cookie-notice' }) => {
  const [visible, setVisible] = useState(true);
  const dismissNotice = () => {
    setVisible(false);
    dismiss();
  };
  return (
    visible && (
      <div className={s.container} data-testid={testId}>
        <div>{children}</div>
        <div>
          <Icon
            type="close"
            factor={1}
            noHoverEffects={false}
            onClick={dismissNotice}
            style={{ transform: 'translateY(4px)' }}
          />
        </div>
      </div>
    )
  );
};

export default CookieNotice;

import React from 'react';
import { observer } from 'mobx-react';
import { navigate } from 'gatsby';
import { Icon } from '@mqd/volt-base';
import { prop } from 'ramda';
import { pageDataStore } from '../../stores';
import * as s from './MobileNav.module.css';

const localStore = {
  collections: null,
  activeCollection: null,
  collectionName: null,
};

// Collections that will not be accessible in mobile
const omittedCollections = ['Core API Explorer'];

// The main and only functional component that's exported
function MobileNav({ links, testId }) {
  localStore.collections = links;
  localStore.activeCollection = links.find(prop('active'));
  localStore.collectionName =
    localStore.activeCollection && localStore.activeCollection.content.props.children;

  return (
    <div className={s.mobileNav} data-testid={testId}>
      <span className={s.dropdownWrap}>
        {CollectionDropdown()}
        {localStore.activeCollection && <>{PageDropdown()}</>}
      </span>
    </div>
  );
}

// Dropdown component for collections
function CollectionDropdown() {
  function navigateTo(e) {
    const selectedCollection = e.target.value;
    const link = localStore.collections.find(
      (collection) => collection.content.props.children === selectedCollection
    ).content.props.to;
    navigate(link);
  }

  return (
    <div className={s.dropdown}>
      <label className={s.label} formfor="collection-select">
        <p className={s.labelText}>{localStore.collectionName || 'Select Collection…'}</p>
        {selectIcon()}
      </label>
      <select
        id="collection-select"
        className={s.select}
        value={localStore.collectionName}
        onChange={navigateTo}
      >
        {/* this option is only available when there is no active collection, such as in the Search page */}
        <option default hidden={!!localStore.collectionName}>
          Select Collection…
        </option>
        {localStore.collections.map((collection) => {
          try {
            const collectionName = collection.content.props.children;
            if (omittedCollections.includes(collectionName)) return null;
            return <option key={collectionName}>{collectionName}</option>;
          } catch (err) {
            return null;
          }
        })}
      </select>
    </div>
  );
}

// Dropdown component for categories and pages
function PageDropdown() {
  const {
    pageInfo: { navTitle },
    siteMapInfo: siteMap,
  } = pageDataStore;
  const categories = siteMap.find(
    (collection) => collection.title === localStore.collectionName
  ).categories;

  // if the page has no categories, it has no pages, and should return null.
  // ex: the Search page
  if (!categories.length) {
    return null;
  }

  function navigateTo(e) {
    // Get information from the select element
    const { options, selectedIndex, value: selectedPage } = e.target;
    // Get category name from the DOM of the selected option
    const categoryName = options[selectedIndex].parentElement.getAttribute('label');
    // Get the pages in that category
    const pages = categories.find((category) => category.name === categoryName).pages;
    // Navigate to the matching page
    navigate(pages.find((page) => page.title === selectedPage).urlPath);
  }

  return (
    <>
      <span className={s.divider}>/</span>
      <div className={s.dropdown}>
        <label className={s.label} formfor="page-select">
          <p className={s.labelText}>{navTitle}</p>
          {selectIcon()}
        </label>
        <select id="page-select" className={s.select} value={navTitle} onChange={navigateTo}>
          {categories.map((category) => (
            <optgroup key={category.name} label={category.name}>
              {category.pages
                .filter((page) => page.status !== 'hidden')
                .map((page) => (
                  <option key={page.title}>{page.title}</option>
                ))}
            </optgroup>
          ))}
        </select>
      </div>
    </>
  );
}

function selectIcon() {
  return <Icon type="caret-down" mod="default" noHoverEffects={true} factor={1} />;
}
export default observer(MobileNav);

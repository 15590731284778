import { utilService as util } from '../services';
import { globalAlertStore } from '../stores';

const cookieNames = {
  betaNotice: 'mdocs_beta-notice',
  welcomeBanner: 'mdocs_welcome-banner',
  cookieNotice: '_marqeta-3.0_cookie-notice',
};

export default {
  init: function initCookies() {
    // TEMPORARY
    deleteBetaCookies();

    // show cookie notice
    setTimeout(() => {
      !util.getCookie(cookieNames.cookieNotice) &&
        globalAlertStore.addAlert('cookieNotice', 'footer', function() {
          util.setCookie({
            name: cookieNames.cookieNotice,
            value: true,
            days: 30,
          });
        });
    }, 1000);
  },
};

function deleteBetaCookies() {
  // delete beta welcome banner
  util.deleteCookie(cookieNames.welcomeBanner);

  // delete beta toast notice
  util.deleteCookie(cookieNames.betaNotice);
}

import { graphql, StaticQuery } from 'gatsby';
import { Layout } from '../layouts';
import { AsciidocNode } from './';
import { pageDataStore } from '../stores';
import { isHomePage, isLandingPage } from '../../lib';

import React from 'react';

const getLayout = (element, props) => {
  const { data, location, pageContext } = props;
  const branch = process.env.GATSBY_BRANCH;
  let path = location.pathname
    .replace(/\.html$/, '') // removes '.html'
    .replace(/\/$/, ''); // removes trailing '/'
  if (data) {
    const asciiNode = new AsciidocNode(data.asciidoc);
    // Must create a clone because React elements are immutable
    const cloneElement = React.cloneElement(element, {
      pageData: asciiNode.data,
      pageHtml: asciiNode.html,
    });
    element = cloneElement;
    pageDataStore.setPageInfo(asciiNode.data);
  } else {
    const urlCollection = props.location.pathname
      .replace(/\/$/, '') // remove trailing slash
      .split('/') // split by remaining '/'
      .pop(); // get last piece

    pageDataStore.setPageInfo({
      description: null,
      h2s: [],
      lastModified: null,
      readTime: null,
      status: null,
      subtitle: null,
      tags: [],
      title: null,
      urlCollection: urlCollection,
      urlPath: null,
      urlTitle: null,
    });
  }
  const unidentifiedPage = !Object.keys(pageContext).includes('id');
  const minimalLayoutPaths = [`${branch ? '/' + branch : ''}/search`];
  const isApiExplorer = (path, spec) => path.endsWith('api-explorer') && !!spec; // generalized check for multi-api explorer

  return (
    <StaticQuery
      query={graphql`
        query siteMapping {
          siteMapping {
            internal {
              content
            }
          }
        }
      `}
      render={(data) => {
        if (!pageDataStore.siteMapInfo.length) {
          // siteMapInfo only needs to be set once
          pageDataStore.setSiteMapInfo(JSON.parse(data.siteMapping.internal.content));
        }
        if (minimalLayoutPaths.includes(path) || isApiExplorer(path, pageContext.specName)) {
          return (
            <Layout data={data} isMinimal={true}>
              {element}
            </Layout>
          );
        } else if (unidentifiedPage) {
          return (
            <Layout data={data} is404={true}>
              {element}
            </Layout>
          );
        } else {
          return (
            <Layout
              data={data}
              isHomeLayout={isHomePage(pageContext.status) || isLandingPage(pageContext.status)}
            >
              {element}
            </Layout>
          );
        }
      }}
    />
  );
};

export default {
  getLayout,
};

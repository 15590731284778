import React, { useState, useEffect } from 'react';
import Response from './Response';
import { pipe, path, pickBy, toPairs, join, map } from 'ramda';
import { Button, Input, Select, Text, Textarea, VSpacer } from '@mqd/volt-base';
import SidenavAccordion from '../../sidenav_accordion/SidenavAccordion';
import { CodeBlock } from '@mqd/volt-codeblock';
import { http } from '../apiWidgetFunctions';
import { userStore } from '../../../stores';
import { utilService } from '../../../services';
import * as s from '../ApiWidget.module.css';

const SendRequestTab = ({ endpoint, method, requestInfo, requestBody, setRequestBody }) => {
  const [queryParams, setQueryParams] = useState({});
  const [pathParams, setPathParams] = useState({});
  const [responseBody, setResponseBody] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [url, setUrl] = useState(null);
  const [cURL, setCURL] = useState(null);
  const [sendingRequest, setSendingRequest] = useState(false);

  //   Set the URL string every time a path or query parameter is changed
  useEffect(() => {
    const baseUrl = process.env.GATSBY_SANDBOX_BASE_URL;
    for (let param in pathParams) {
      endpoint = endpoint.replace(new RegExp(`{${param}}`, 'gi'), pathParams[param]);
    }
    let url = `${baseUrl || '-missingUrl-'}${endpoint}`;
    const query = pipe(
      () => queryParams,
      pickBy((val) => !!val),
      toPairs,
      map(join('=')),
      join('&')
    )();
    if (query) url += `?${query}`;
    setUrl(url);
  }, [queryParams, pathParams]);

  //   Set the cURL string every time the request body or API URL is changed
  useEffect(() => {
    const { app_token: appToken, auth_token: authToken } = userStore.sandboxInfo || {};
    const headers = ` -H "accept: application/json" -H "Content-Type: application/json" -H "Authorization: Basic ${btoa(
      `${appToken}:${authToken}`
    )}"`;
    let jsonBody = requestBody;
    try {
      jsonBody = JSON.parse(jsonBody);
    } catch (e) {}
    const postJSON = JSON.stringify(jsonBody || {}).replace(/"/g, '\\"');
    setCURL(`curl -X ${method.toUpperCase()} "${url}" ${headers} -d "${postJSON}"`);
  }, [requestBody, url]);

  async function sendHttp() {
    setSendingRequest(true);
    let response;
    try {
      const sandboxInfo = userStore.sandboxInfo;
      response = await http({ url, method, requestBody, sandboxInfo });
    } catch (err) {
      response = err;
    } finally {
      setResponseBody(response.body);
      setResponseStatus(response.status);
      setSendingRequest(false);
      utilService.segmentTrackEvent('Docs Site API Widget Clicked', {
        environment: process.env.GATSBY_SANDBOX_BASE_URL,
        userId: userStore.userInfo?.token || '',
        apiEndpoint: endpoint,
        apiAction: method,
      });
    }
  }

  function editReqBody(e) {
    setRequestBody(e.target.value);
  }

  function editQueryParams(value, name) {
    const paramsList = requestInfo['Query Parameters'];
    const qParams = {};
    paramsList.forEach((param) => {
      qParams[param.name] = param.name === name ? value : queryParams[param.name];
    });
    setQueryParams(qParams);
  }

  function editPathParams(value, name) {
    const paramsList = requestInfo['Path Parameters'];
    const pParams = {};
    paramsList.forEach((param) => {
      pParams[param.name] = param.name === name ? value : pathParams[param.name];
    });
    setPathParams(pParams);
  }

  const navigateToGetSandboxURL = () => {
    window.location.href = process.env.GATSBY_AMC_DEVELOPMENT_URL;
  };

  function renderButton() {
    if (sendingRequest) {
      return (
        <Button type="primary" disabled={true}>
          Sending
        </Button>
      );
    }
    if (!userStore.loggedIn) {
      return (
        <Button type="primary" onClick={userStore.logIn}>
          Sign in to send request
        </Button>
      );
    } else {
      const job_state = userStore.sandboxJobState;
      if (job_state === null) {
        return (
          <Button type="primary" onClick={navigateToGetSandboxURL}>
            Create sandbox to send request
          </Button>
        );
      }
    }
    return (
      <Button type="primary" onClick={sendHttp}>
        Send request
      </Button>
    );
  }

  return (
    <div>
      {!!path(['Request Body'], requestInfo) && (
        <>
          <VSpacer factor={3} />
          <Textarea label="Request body" onChange={editReqBody} value={requestBody} rows={7} />
        </>
      )}

      {!!path(['Path Parameters', 'length'], requestInfo) &&
        requestInfo['Path Parameters'].map((param) => (
          <React.Fragment key={JSON.stringify(param)}>
            <VSpacer factor={3} />
            <Text type="h6" inline={true}>
              {param.name.toLowerCase()}
            </Text>
            {!param.required && <Text inline={true}>&nbsp;(optional)</Text>}
            <Text type="body-sm">path param, {param.type}</Text>
            <VSpacer />
            <Input
              value={pathParams[param.name] || ''}
              onChange={(e) => editPathParams(e.target.value, param.name)}
            />
          </React.Fragment>
        ))}

      {!!path(['Query Parameters', 'length'], requestInfo) &&
        requestInfo['Query Parameters'].map((param) => (
          <React.Fragment key={JSON.stringify(param)}>
            <VSpacer factor={3} />
            <Text type="h6" inline={true}>
              {param.name.toLowerCase()}
            </Text>
            {!param.required && <Text inline={true}>&nbsp;(optional)</Text>}
            <Text type="body-sm">query param, {param.type}</Text>
            <VSpacer />
            {param.type === 'boolean' ? (
              <Select
                onChange={(val) => editQueryParams(val, param.name)}
                value={queryParams[param.name] || ''}
                placeholder="Select an option"
                options={['true', 'false']}
              />
            ) : (
              <Input
                value={queryParams[param.name] || ''}
                onChange={(e) => editQueryParams(e.target.value, param.name)}
              />
            )}
          </React.Fragment>
        ))}

      <VSpacer factor={2} />
      <div className={s.accordion}>
        <SidenavAccordion
          width="100%"
          sections={[
            {
              title: 'Generated cURL',
              content: <CodeBlock content={[{ language: 'cURL', content: cURL }]}></CodeBlock>,
            },
          ]}
        />
      </div>
      <div>
        <VSpacer factor={3} />
        {renderButton()}
      </div>
      <Response sending={sendingRequest} status={responseStatus} body={responseBody} />
    </div>
  );
};

export default SendRequestTab;

import React, { useState } from 'react';
import ApiWidget from '../api_widget/ApiWidget';
import { Text, Icon, HSpacer } from '@mqd/volt-base';
import * as s from './ApiExplorer.module.css';

const ApiExplorerSection = ({ name, description, contents, specName }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={s.container}>
      <div
        className={s.wrapper}
        data-testid="api-explorer-section"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <Icon
          type="caret-right"
          mod="default"
          factor={1}
          noHoverEffects={true}
          style={visible && { transform: 'rotate(90deg)' }}
        />
        <HSpacer factor={1} />
        <Text type="h6" inline={true}>
          {name}
        </Text>
        <HSpacer factor={1} />
        <Text type="body-sm" inline={true}>
          {description}
        </Text>
      </div>
      {visible && (
        <div>
          {contents.map((arr) => (
            <ApiWidget endpoint={arr[0]} method={arr[1]} specName={specName} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ApiExplorerSection;

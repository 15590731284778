/**
 * Submits newsletter subscription form to Marketo API
 * @param {int} formId - ID of Marketo form to submit to
 * @param {object} formInput - JSON object containing form fields to submit
 */
export const submitMarketoForm = async (formId, formInput) => {
  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(formInput.Email)) throw new Error('Invalid Email');

  const form = window.MktoForms2.getForm(formId);
  if (!form) throw new Error('Issue loading form');
  form.setValues(formInput);
  if (!form.validate()) throw new Error('Invalid input');
  form
    .onSuccess(() => {
      console.log('form submitted successfully');
      return false; // prevents default Mkto reloading of page
    })
    .submit();
};

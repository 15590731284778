import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
const version = require('../../package.json').version;

datadogRum.init({
  applicationId: 'f99b0dc7-bdad-48d5-9ebf-05b10fd21520',
  clientToken: 'pubc62233213921d7a24363c2224b79b36a',
  site: 'datadoghq.com',
  service: 'docs',
  env: `${process.env.GATSBY_ENVIRONMENT}`,
  version: version,
  sampleRate: 100,
  trackInteractions: true,
});

datadogLogs.init({
  clientToken: 'pubc62233213921d7a24363c2224b79b36a',
  site: 'datadoghq.com',
  service: 'docs',
  env: `${process.env.GATSBY_ENVIRONMENT}`,
  version: version,
  forwardErrorsToLogs: false,
  sampleRate: 100,
});

import axios from 'axios';
import CryptoJS from 'crypto-js';

const clientId = process.env.GATSBY_REDSEA_CLIENT_ID;
const tokenUrl = `${process.env.GATSBY_SSO_LOGIN_URL}/redsea/oauth2/token`;

// Updated SSO
function base64UrlEscape(base64Str) {
  return base64Str
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function getRedirectURI() {
  return process.env.NODE_ENV === 'development'
    ? `${window.location.origin}/auth-callback`
    : `${window.location.origin}/docs/auth-callback`;
}

// generate a code verifier for PKCE
function generateCodeVerifier() {
  const numBytes = 32;
  const randomBytes = CryptoJS.lib.WordArray.random(numBytes);
  const base64Str = randomBytes.toString(CryptoJS.enc.Base64);
  const codeVerifier = base64UrlEscape(base64Str);
  return codeVerifier;
}

// generate a code challenge for PKCE from a code verifier
function generateCodeChallenge(codeVerifier) {
  const wordArray = CryptoJS.SHA256(codeVerifier);
  const base64Str = wordArray.toString(CryptoJS.enc.Base64);
  const codeChallenge = base64UrlEscape(base64Str);
  return codeChallenge;
}

function generateOauthParams(state) {
  const codeVerifier = generateCodeVerifier();
  localStorage.setItem('codeVerifier', codeVerifier);
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const redirectUri = getRedirectURI();

  const params = {
    client_id: clientId,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    redirect_uri: redirectUri,
    response_type: 'code',
    state: state,
  };

  return params;
}

function convertObjectToQuery(obj) {
  const keys = Object.keys(obj);
  return keys.map(key => `${key}=${encodeURIComponent(obj[key])}`).join('&');
}

function convertObjectToSearch(obj) {
  const keyCount = Object.keys(obj).length;
  if (keyCount > 0) {
    return '?' + convertObjectToQuery(obj);
  } else {
    return '';
  }
}

async function exchangeAuthCode(authCode) {
  const codeVerifier = localStorage.getItem('codeVerifier');
  localStorage.removeItem('codeVerifier');
  const redirectUri = getRedirectURI();

  const payload = {
    client_id: clientId,
    code: authCode,
    code_verifier: codeVerifier,
    grant_type: 'authorization_code',
    redirect_uri: redirectUri,
  };
  const data = convertObjectToQuery(payload);
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
  };

  const response = await axios.post(tokenUrl, data, {
    headers,
  });

  const accessToken = response.data && response.data.access_token;
  return accessToken;
}

export default {
  generateOauthParams,
  exchangeAuthCode,
  convertObjectToSearch,
};

import { utilService as util } from './';

const UTM_PARAMS = ['utm_campaign', 'utm_medium', 'utm_source'];

const cleanUtmParams = () => {
  const utmTouchValue = localStorage.getItem('utm_touch');
  const utmTouchNumber = Number(utmTouchValue);
  const utmTouchDate = new Date(utmTouchNumber);
  const utmParamsApplicable =
    util.isValidDate(utmTouchDate) && utmTouchIsRecentEnough(utmTouchDate);
  if (!utmParamsApplicable) {
    removeAllUtmParamsFromLocalStorage();
  }
};

const utmTouchIsRecentEnough = date => {
  // recent enough is within the last 90 days
  return date > util.subtractDaysFromDate(new Date(), 90);
};

const removeAllUtmParamsFromLocalStorage = () => {
  UTM_PARAMS.forEach(function(param) {
    localStorage.removeItem(param);
  });
  localStorage.removeItem('utm_touch');
};

const trackUtmParams = () => {
  cleanUtmParams();
  const utmValues = {};
  UTM_PARAMS.forEach(function(param) {
    const paramValue = util.getParam(param);
    if (paramValue) {
      utmValues[param] = paramValue;
    }
  });
  if (Object.keys(utmValues).length) {
    removeAllUtmParamsFromLocalStorage();
    Object.keys(utmValues).forEach(function(param) {
      localStorage.setItem(param, utmValues[param]);
    });
    localStorage.setItem('utm_touch', Date.now());
  }
};

export default {
  cleanUtmParams,
  trackUtmParams,
  UTM_PARAMS,
};

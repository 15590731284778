import { marked } from 'marked';
import DOMPurify from 'dompurify';

/**
 * The descripition field of the OpenAPI definition may contain raw asciidoc syntax that won't render correctly in the API widgets.
 * This function sanitizes the asciidoc, removes links, and converts it to markdown.
 * @param {string} value - the description field of the OpenAPI definition
 * @returns sanitized string for use in API widgets
 */
export const parseDescription = (value = '') => {
  // Remove instances of asciidoc links
  let str = value.replaceAll(/<<.+?, (.+?)>>/g, '$1');
  str = str.replaceAll(/link:.+?\[(.+?)\]/g, '$1');

  const html = marked.parse(str || '');
  return DOMPurify.sanitize(html);
};

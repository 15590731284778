import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Icon, Text, Link, VSpacer } from '@mqd/volt-base';
import { pageDataStore } from '../../stores';
import { observer } from 'mobx-react';
import * as s from './RelatedDocs.module.css';

const RelatedDocs = observer(
  class RelatedDocs extends Component {
    render() {
      const { data } = this.props;
      if (data.related.length === 0) {
        return null;
      }
      return (
        <div className={s.related}>
          <Text type="h3">Related materials</Text>
          {this.renderList()}
        </div>
      );
    }

    renderList() {
      return <ul className={s.list}>{this.renderListItems()}</ul>;
    }

    renderListItems() {
      const { data } = this.props;
      return data.related.map((relate) => (
        <div className={s.listItem} key={relate.path} data-testid="related-docs-link">
          <VSpacer factor={2} />
          <Link href={`${process.env.GATSBY_DOCS_URL}${relate.path}`}>
            {relate.collection === 'Guides' ? (
              <Icon mod="active" noHoverEffects={true} type="docs" />
            ) : (
              <Icon mod="active" noHoverEffects={true} type="settings" />
            )}
            <Text type="h6" className={s.listItemText}>
              {relate.collection} / {relate.title}
            </Text>
          </Link>
        </div>
      ));
    }
  }
);

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allTag {
            edges {
              node {
                id
                name
                internal {
                  type
                  mediaType
                  contentDigest
                  content
                  owner
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const foundPaths = {};
        const currentPage = pageDataStore.pageInfo;
        const isRelated = (page) =>
          /*
            Returns true for pages which:
              1. don't represent the currently rendering page
              2. are tagged with any of the currently rendering page's tags
              3. have not yet been accounted for
          */
          // 1
          page.path !== currentPage.urlPath &&
          // 2
          currentPage.tags.filter((tag) => ~page.tags.indexOf(tag)).length &&
          // 3
          (foundPaths[page.path] ? false : (foundPaths[page.path] = true));
        // create a map of tag -> array of pages with that tag
        const relatedMap = {};
        data.allTag.edges
          // turn content from string into object
          .map((edge) => JSON.parse(edge.node.internal.content))
          .forEach((page) => {
            if (!isRelated(page)) return;
            // only add for the first matching tag
            const tagKey = page.tags.find((tag) => ~currentPage.tags.indexOf(tag));
            relatedMap[tagKey] = relatedMap[tagKey] || [];
            relatedMap[tagKey].push(page);
          });
        // create an array of arrays to iterate over and select the related
        // level 0 array -- ordered by currentPage tag order
        // level 1 array -- ordered by weight
        const byWeight = (a, b) => Math.max(Math.min(a.weight - b.weight, 1), -1);
        const alphabetical = (a, b) => (a.path === b.path ? 0 : a.title < b.title ? -1 : 1);
        const sortOrder = (a, b) => byWeight(a, b) || alphabetical(a, b);
        const relatedLists = currentPage.tags
          .map((tag) => relatedMap[tag])
          .filter((l) => l)
          .map((list) => list.sort(sortOrder));
        const pagesToTake = 5;
        const related = [];
        let i = 0;
        let currentIndex;
        let currentList;
        // take the 1st of each array
        // then the 2nd of each array,
        // and the nth of each array,
        // till there are enough related pages, or no more choices
        while (related.length < pagesToTake && relatedLists.length > 0 && i < 1000) {
          currentIndex = i % relatedLists.length;
          currentList = relatedLists[currentIndex];
          related.push(currentList.shift());
          if (currentList.length === 0) {
            relatedLists.splice(currentIndex, 1);
          } else {
            i++;
          }
        }
        return <RelatedDocs data={{ related }} page={currentPage} />;
      }}
    />
  );
};

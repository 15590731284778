import axios from 'axios';
var OpenAPISampler = require('openapi-sampler');
import mergeAllOf from '@stoplight/json-schema-merge-allof';
import { Resolver } from '@stoplight/json-ref-resolver';
import {
  __,
  applySpec,
  curry,
  filter,
  find,
  forEachObjIndexed,
  head,
  last,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  toPairs,
} from 'ramda';
const yaml = require('js-yaml');
import { datadogLogs } from '@datadog/browser-logs';

/**
 * Retrieves all endpoints from the selected OpenAPI spec
 * @param {string} specName - name of the spec to fetch endpoints from
 */
const retrieveAllEndpoints = (specName) => {
  return new Promise((resolve, reject) => {
    retrieveFullSpec(specName)
      .then((spec) => {
        const groupedEndpoints = groupEndpointsByTag(spec, specName);
        resolve(groupedEndpoints);
      })
      .catch(reject);
  });
};

/**
 * Groups endpoints by their category tag to be displayed in the API explorer
 * @param {object} spec - OpenAPI spec object
 * @param {string} specName - name of the spec. For use in API explorer
 * @returns {object} - object containing each category tag, its description, and the endpoints that belong to that category
 */
const groupEndpointsByTag = (spec, specName) => {
  let tags = {};
  forEachObjIndexed((endpoints, key) => {
    forEachObjIndexed((endpointInfo, method) => {
      const tagName = path(['tags', 0], endpointInfo);
      if (tags[tagName]) {
        path([tagName, 'endpoints'], tags).push([key, method]);
      } else {
        tags[tagName] = {
          description: prop('description', find(propEq('name', tagName), path(['tags'], spec))),
          endpoints: [[key, method]],
          spec: specName,
        };
      }
    }, endpoints);
  }, path(['paths'], spec));
  return tags;
};

/**
 * Retrieve endpoint info from OpenAPI spec
 * @param {string} endpoint - the endpoint being hit by this API call
 * @param {string} method - the HTTP method of the API call
 * @param {string} specName - name of the spec this endpoint belongs to
 * @returns {object} - object containing this endpoint's request and response definition for use in the API widget
 */
const retrieveEndpointInfo = function ({ endpoint, method, specName }) {
  return new Promise((resolve, reject) => {
    retrieveFullSpec(specName)
      .then((spec) => {
        const endpointObject = path(['paths', endpoint, method], spec);
        if (!endpointObject) {
          console.error(`${method} ${endpoint} not found in spec`);
          return;
        }
        const endpointInfo = pipe(
          () => endpointObject,
          applySpec({
            summary: prop('summary'),
            'Request definition': extractRequestDefinitionFromSpec(spec),
            'Response definition': extractResponseDefinitionFromSpec(spec),
          })
        )();
        resolve(endpointInfo);
      })
      .catch(reject);
  });
};

/**
 * Extracts the endpoint's request definition
 * @param {object} spec - OpenAPI spec object
 * @param {object} endpointObject - OpenAPI operation object that describes an API operation on a path
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#operation-object
 * @returns {object} - object containing the body and parameters of this request
 */
const extractRequestDefinitionFromSpec = (spec) => (endpointObject) => {
  const { parameters } = endpointObject;
  return {
    'Request Body': extractRequestBodyFromSpec(spec, endpointObject),
    'Path Parameters': extractAndTransformParams('path', parameters),
    'Query Parameters': extractAndTransformParams('query', parameters),
  };
};

/**
 * Extracts the body of the request
 * @param {object} spec - OpenAPI spec object
 * @param {object} endpointObject - OpenAPI operation object that describes an API operation on a path
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#operation-object
 * @returns {object} - nested object that make up the body of the request
 */
const extractRequestBodyFromSpec = (spec, endpointObject) => {
  let body;
  if (spec.openapi) {
    // OpenAPI 3
    body = pipe(
      () => endpointObject,
      prop('requestBody'),
      pathOr('', ['content', 'application/json', 'schema'])
    )();
  } else {
    // Swagger 2
    body = pipe(
      () => endpointObject,
      prop('parameters'),
      find(propEq('in', 'body')),
      pathOr('', ['schema'])
    )();
  }
  if (!body) return;
  return {
    'request object': getTableFromObject(body, ['request object'], spec),
    'sample request body': generateSampleSchema(body, spec),
  };
};

/**
 * Generates a sample request string for the given schema definition
 * @param {object} body - the schema definition object
 * @param {object} spec - OpenAPI spec object
 * @returns {string} - a stringified JSON object containing a sample request body
 */
const generateSampleSchema = (body, spec) => {
  const definitionObject = mergeAllOf(body); //resolve instances of `allOf` in the spec
  if (!definitionObject) return null;
  const schema = JSON.stringify(
    OpenAPISampler.sample(definitionObject, { skipNonRequired: true }, spec),
    undefined,
    4
  );
  return schema === '{}' ? '' : schema;
};

/**
 * Gets an object of field name, type, required, and children for each field in the parent object to be rendered as a table in the view
 * Will be called recursively to populate the children field for nested objects
 * @param {string} object - the schema definition object
 * @param {array} parent - array containing the parent fields, to be used in the breadcrumb object
 * @param {object} spec - OpenAPI spec object
 * @returns {object} - a nested object containing the fields and their information and children
 */
const getTableFromObject = curry((object, parent, spec) => {
  const definitionObject = mergeAllOf(object); // resolve instances of `allOf` in the definition
  if (!definitionObject) return;
  const definitionTable = {};
  for (let fieldName in definitionObject.properties) {
    const schema = definitionObject.properties[fieldName];
    definitionTable[fieldName] = {
      name: fieldName,
      type: schema.type,
      required: definitionObject.required && definitionObject.required.includes(fieldName),
      description: schema.description,
      children:
        schema.type === 'object' ? getTableFromObject(schema, [...parent, fieldName], spec) : null,
      parent: [...parent, fieldName],
    };
  }
  return definitionTable;
});

/**
 * Extracts the parameters of a given type from an array of all parameters and transforms them for use in the widget
 * @param {string} paramType - the type of parameter to extract: query or path
 * @param {array} parameters - array of OpenAPI 2 or 3 Parameter objects
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/2.0.md#parameterObject
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#parameterObject
 * @returns {array} - array of transformed parameter objects
 */
const extractAndTransformParams = (paramType, parameters) => {
  if (!parameters) return;
  return pipe(
    () => parameters,
    filter(propEq('in', paramType)),
    map(
      applySpec({
        name: prop('name'),
        required: prop('required'),
        description: prop('description'),
        type: (param) => pathOr(prop('type', param), ['schema', 'type'], param), // OpenAPI 3 has type nested under schema, Swagger 2 does not
      })
    )
  )();
};

/**
 * Extracts the endpoint's response definition
 * @param {object} spec - OpenAPI spec
 * @param {object} rawResponses - OpenAPI responses object
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/2.0.md#responses-object
 * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#responsesObject
 * @returns {array} - an array of objects representing the expected responses of the operation
 */
const extractResponseDefinitionFromSpec =
  (spec) =>
  ({ responses: rawResponses }) => {
    const schemaPath = spec.openapi ? ['content', 'application/json', 'schema'] : ['schema']; //Schema type is nested in OpenAPI 3
    const responses = pipe(
      () => rawResponses,
      toPairs,
      map(
        applySpec({
          type: head,
          message: path([1, 'description']),
          'response object': pipe(
            last,
            pathOr('', schemaPath),
            getTableFromObject(__, ['response object'], spec)
          ),
        })
      )
    )();
    return responses;
  };

/**
 * Retrieves the OpenAPI spec
 * @param {string} specName - name of the OpenAPI spec to fetch
 */
const retrieveFullSpec = (specName) =>
  new Promise((resolve, reject) => {
    // As more specs are added, we would add them here or move this out to a constants file as necessary
    const specMapping = {
      'swagger.json': process.env.GATSBY_OAPISPEC_URL,
      'simulations.yaml': process.env.GATSBY_SIMULATIONS_DEF_URL,
    };
    if (window[specName]) {
      resolve(window[specName]);
      return;
    }
    axios
      .get(specMapping[specName], {
        timeout: 5000,
      })
      .then(async (response) => {
        // resolve all $ref pointers in the definition
        const resolver = new Resolver();
        let schema;
        if (specMapping[specName].slice(-4) == 'json') {
          schema = await resolver.resolve(response.data);
          // Additional transformation required if type is yaml
        } else {
          const transformedResponse = yaml.load(response.data, 'utf8');
          schema = await resolver.resolve(transformedResponse);
        }
        window[specName] = schema.result;
        resolve(schema.result);
      })
      .catch((err) => {
        const error = `Fatal: Unable to retrieve OpenAPI spec.\n${err.message}`;
        reject(error);
      });
  });

/**
 *
 * @param {string} url - the URL to make the API request to
 * @param {string} method - the method of the API request
 * @param {string} requestBody - stringified request body object
 * @param {object} sandboxInfo - object containing the user's sandbox info from userStore
 * @returns {object} - object containing the body and status of the response from the API call
 */
const http = async ({ url, method, requestBody, sandboxInfo }) => {
  const { app_token: appToken, auth_token: authToken } = sandboxInfo || {};
  requestBody = requestBody || {};
  const apiMethod = method.toLowerCase();
  const headers = {
    Authorization: `Basic ${btoa(`${appToken}:${authToken}`)}`,
    accept: 'application/json',
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios({
      method: apiMethod,
      url: url,
      data: requestBody,
      headers: headers,
      timeout: 5000,
    });
    const { data, status } = response;
    datadogLogs.logger.info('API call success', {
      app_token: appToken,
      request_method: apiMethod,
      request_url: url,
      response_status: status,
    });
    return { body: data, status };
  } catch (err) {
    let data, status;
    if (err.response) {
      ({ data, status } = err.response);
      datadogLogs.logger.info('API call error', {
        app_token: appToken,
        request_method: apiMethod,
        request_url: url,
        response_status: status,
      });
      const error = `Fatal: Unable to send ${method} request to ${url}.\n${data.error_message}`;
      console.error(error);
    } else {
      data = 'Request failed, please try again.';
      status = 500;
      datadogLogs.logger.error('API widget request failure', {
        app_token: appToken,
        request_method: apiMethod,
        request_url: url,
      });
    }
    throw { body: data, status };
  }
};
export { retrieveAllEndpoints, retrieveEndpointInfo, http };

import React from 'react';
import * as s from './Chiclet.module.css';
import { Colors, Text } from '@mqd/volt-base';

const Chiclet = ({ testId, text }) => (
  <div className={s.container} style={{ backgroundColor: Colors.washColor }} data-testid={testId}>
    <Text type="h6" color={Colors.accentColor} testId={`${testId}-text`}>
      {text}
    </Text>
  </div>
);

export default React.memo(Chiclet);

import { utmHelper } from './';

const portalId = '4508301';
const formId = '74632563-69b1-4b9c-b8c9-c976f88cd355';

function insertHubspotScript(htmlDocument) {
  const script = htmlDocument.createElement('script');
  script.src = '//js.hsforms.net/forms/v2.js';
  script.charset = 'utf-8';
  script.type = 'text/javascript';
  script.defer = true;
  htmlDocument.head.append(script);
}

function stubJQuery() {
  // jQuery needs to be stubbed for HubSpot
  // https://integrate.hubspot.com/t/form-callback-throws-unrelated-jquery-error/77/7
  window.jQuery = () => ({ change() {}, trigger() {} });
}

function fillFormFields(form, fields) {
  fields.forEach(field => {
    const { name, calc } = field;
    const fieldEl = form.querySelector(`[name="${name}"]`);
    fieldEl && (fieldEl.value = calc());
  });
}

function fillUtmFields(form) {
  utmHelper.cleanUtmParams();
  utmHelper.UTM_PARAMS.forEach(function(param) {
    const field = form.querySelector('[name="' + param + '"]');
    const utmValue = localStorage.getItem(param);
    if (field && utmValue !== null) {
      field.value = utmValue;
    }
  });
}

function create(hbspt, options) {
  hbspt && hbspt.forms && hbspt.forms.create && hbspt.forms.create(options);
}

export default {
  create,
  fillFormFields,
  fillUtmFields,
  formId,
  insertHubspotScript,
  portalId,
  stubJQuery,
};

import { Parser } from 'html-to-react';
import { htmlProcessingInstructions } from '.';

class HtmlProcessingService {
  process(htmlString) {
    const isValidNode = () => true;
    const parsedHtml = new Parser().parseWithInstructions(
      htmlString,
      isValidNode,
      htmlProcessingInstructions
    );

    return {
      level2Headings: this._findLevel2Headings(parsedHtml),
      html: parsedHtml,
    };
  }

  _findLevel2Headings(parsedHtml) {
    return parsedHtml
      .map(this._findH2s)
      .filter((el) => el)
      .map(this._createHeadingObj);
  }

  _findH2s = (el) => {
    if (el.type === 'h2') {
      return el;
    }
    if (!el.props) {
      return null;
    }
    if (el.props.type === 'h2') {
      return el.props;
    }
    const { children } = el.props;
    if (children instanceof Array) {
      return children.find(this._findH2s);
    }
    return null;
  };

  _createHeadingObj = (el) => {
    const { children, id } = el.props;
    const stringValue = (item) => (typeof item === 'string' ? item : '');
    const getMethod = (children) => {
      const methodEl = children.find(
        (child) => child.props && child.props.hasOwnProperty('data-heading-method')
      );
      if (!methodEl) return '';
      return methodEl.props['data-heading-method'];
    };
    return {
      title: children.map(stringValue),
      anchor: id,
      httpMethod: getMethod(children).toUpperCase(),
    };
  };
}

const htmlProcessingService = new HtmlProcessingService();
export default htmlProcessingService;

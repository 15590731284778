import React, { useState, useEffect } from 'react';
import { path } from 'ramda';
import ApiExplorerSection from './ApiExplorerSection';
import { retrieveAllEndpoints } from '../api_widget/apiWidgetFunctions';

const ApiExplorer = ({ specName }) => {
  const [endpoints, setEndpoints] = useState({});
  useEffect(() => {
    if (typeof specName === 'string') {
      retrieveAllEndpoints(specName).then(setEndpoints);
    } else {
      // Combine multiple definitions in one API explorer page
      const promises = specName.map((spec) => retrieveAllEndpoints(spec));
      Promise.all(promises)
        .then((results) => {
          const combinedEndpoints = Object.assign(...results);
          setEndpoints(combinedEndpoints);
        })
        .catch(console.error);
    }
  }, []);

  return (
    <div id="api-explorer" data-testid="api-explorer">
      {Object.keys(endpoints)
        .sort()
        .map((tagName) => {
          return (
            <ApiExplorerSection
              name={tagName}
              description={path([tagName, 'description'], endpoints)}
              contents={path([tagName, 'endpoints'], endpoints)}
              specName={path([tagName, 'spec'], endpoints)}
            />
          );
        })}
    </div>
  );
};

export default ApiExplorer;

/**
 * @param {Object} env - https://nodejs.org/api/process.html#process_process_env
 * @returns {string} DEV|QA|PROD
 */
const determineActiveEnvironment = (env) => {
  // ACTIVE_ENV is set in the package.json `scripts`
  // NODE_ENV is provided by Gatsby
  const incomingEnv = env.ACTIVE_ENV || env.NODE_ENV || 'development';

  return incomingEnv === 'development' ? 'DEV' : incomingEnv;
};

// Landing pages urls
const _landingPages = new Set(['/docs/developer-guides', '/developer-guides', '/docs', '']);

/**
 * Strips any number of trailing backslashes on a url string
 * @param  {String} url generally, the fullpath url
 * @return {String}
 */
const stripTrailingBackslashes = (url) => url.replace(/\/*$/, '');

/**
 * Gets full path from adoc's url collection and title
 * @param  {String} urlCollection Data from the urlCollection attribute from adoc header
 * @param  {String} urlTitle      Data from the urlTitle attribute from adoc header
 * @return {String} Adoc fullpath with trailing backslashes stripped out
 */
const getAdocFullPath = (urlCollection, urlTitle) =>
  stripTrailingBackslashes(`/${[urlCollection, urlTitle].join('/')}`);

/**
 * Checks whether page status is homePage
 * @param  {String} status page status
 * @return {Boolean}
 */
const isHomePage = (status) => status === 'homePage';

/**
 * Checks whether page status is landingPage
 * @param  {String} status page status
 * @return {Boolean}
 */
const isLandingPage = (status) => status === 'landingPage';

/**
 * Checks whether page status is hidden
 * @param  {String} status page status
 * @return {Boolean}
 */
const isHiddenPage = (status) => status === 'hidden';

module.exports = {
  determineActiveEnvironment,
  getAdocFullPath,
  stripTrailingBackslashes,
  isHomePage,
  isLandingPage,
  isHiddenPage,
};

import { userStore } from '../stores';

// This file name and other items subject to change as more functions may be added, and divided out later on
const utilService = {
  copyToClipboard(text) {
    const textArea = document.createElement('textArea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.zIndex = -100; // hidden away
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  },

  // Usage:
  // const fn = (event) => ...
  // const debouncedHandler = debounced(200, fn)
  // window.addEventListener("scroll", debouncedHandler)
  debounced(delay, fn) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  },

  // returns the value of a query parameter `p` in the URL
  getParam(p, fromURL = window.location.search) {
    const match = RegExp('[?&]' + p + '=([^&]*)').exec(fromURL);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  },

  // returns url with an added param. Does NOT change the Url itself
  addParam(p, value) {
    const paramString = location.search.slice(1); // remove the ?
    const newParam = `${p}=${value}`;
    let replaced = false;
    let params = [];
    if (!!paramString) {
      params = paramString.split('&');
    }
    params = params.map((pair) => {
      if (pair.split[0] === p) {
        // replace value if it's the same prop name
        replaced = true;
        return newParam;
      } else {
        return pair;
      }
    });

    if (!replaced) {
      // if no value was replaced, add on the value as it is
      params.push(newParam);
    }
    const newParamString = `?${params.join('&')}`;

    return `${location.origin}${location.pathname}${newParamString}${location.hash}`;
  },

  // returns url with a removed param. Does NOT change the Url itself
  removeParam(p) {
    const paramString = location.search.slice(1); // remove the ?
    const params = paramString.split('&');
    const trimmedParams = params.filter((param) => param.split('=')[0] !== p);
    let newParamString = '';
    if (trimmedParams.length) {
      newParamString = `?${trimmedParams.join('&')}`;
    }
    return `${location.origin}${location.pathname}${newParamString}${location.hash}`;
  },

  isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  },

  subtractDaysFromDate(date, days) {
    return new Date(date.getTime() - this.daysToMilliseconds(days));
  },

  daysToMilliseconds(days) {
    return days * 24 * 60 * 60 * 1000;
  },

  setCookie(params) {
    const { name, value, days, domain, path } = params;
    const expires = new Date();
    expires.setTime(expires.getTime() + this.daysToMilliseconds(days));
    // Purposely set the domain to be 'false' at the moment. All cookies to use default set domains.
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toGMTString()}${
      false ? `; domain=${domain}` : ``
    }; path=${path || `/`}`;
  },

  getCookie(name) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      if (!!~cookies[i].indexOf(name)) {
        return decodeURIComponent(cookies[i].split('=')[1]);
      }
    }
    return null;
  },

  deleteCookie(name) {
    const cookie = this.getCookie(name);
    cookie && this.setCookie({ name: name, days: -1, value: null });
  },

  // https://support.google.com/adwords/answer/6299296
  getGclid() {
    return this.getCookie('gclid');
  },

  ensureDeviceId() {
    const deviceId = this.getCookie('device_id');
    if (deviceId) {
      return deviceId;
    } else {
      const uuid = this.uuidv4();
      this.setCookie({
        name: 'device_id',
        value: uuid,
        days: 90,
        // Temporarily commented out. Currently will set all cookies to the default domain.
        // domain: this.removeSubdomain(location.hostname),
      });
      return uuid;
    }
  },

  removeSubdomain(hostname) {
    return hostname.split('.').slice(-2).join('.');
  },

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0;
      var v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },

  checkAtInterval(params) {
    const { limit, condition, finalAction, msBetweenChecks } = params;
    let checkCount = 0;
    const interval = setInterval(() => {
      if (checkCount > limit || condition()) {
        clearInterval(interval);
        finalAction();
      }
      checkCount++;
    }, msBetweenChecks);
  },

  convertSearchToObject(search) {
    return decodeURIComponent(search)
      .substring(1)
      .split('&')
      .reduce(function (result, value) {
        var parts = value.split('=');
        if (parts[0]) result[parts[0]] = parts[1];
        return result;
      }, {});
  },

  // Segment Analytics
  segmentIdentifyUser() {
    if (userStore.loggedIn) {
      const { token, first_name, last_name, email, phone } = userStore.userInfo;
      window.analytics &&
        window.analytics.identify(token, {
          first_name,
          last_name,
          email,
          phone,
        });
    }
  },
  segmentTrackPage() {
    window.analytics && window.analytics.page();
    this.segmentIdentifyUser();
  },
  segmentTrackEvent(eventName, eventProperties = {}) {
    window.analytics && window.analytics.track(eventName, eventProperties);
  },
  renderIf(condition, content) {
    if (condition) {
      return content;
    } else {
      return null;
    }
  },
  /**
   * Converts asciidoc lines into an array of objects with their key value pairs.
   * @param  {String} data The stringified version of asciidoc lines
   * @param  {String} [splitOnCharacter='-'] Splits lines into groups on optional separator
   * @return {Array}  Array of objects
   */
  jsonToFormattedArray(data, splitOnCharacter = '-') {
    const formattedArray = [];
    let currentGroup = {};
    let parsedData = JSON.parse(decodeURIComponent(data));

    const getKeyValByLine = (line) => line.split(/:(.+)/).map((s) => s.trim());
    Object.entries(parsedData).forEach((entry) => {
      const [_, line] = entry;
      if (line === splitOnCharacter) {
        formattedArray.push(currentGroup);
        currentGroup = {};
      } else {
        const [key, val] = getKeyValByLine(line);
        currentGroup[key] = val;
      }
    });
    formattedArray.push(currentGroup);

    return formattedArray;
  },
  /**
   * Makes a string lowercase and replaces spaces with '-'
   * @param {String} str
   */
  slugify(str) {
    return str.toLowerCase().replace(/\s+/g, '-');
  },
  /**
   * Resizes a textarea element to fit the height of the text it contains, up until a specified max height
   * @param {Node} target - DOM node of the textarea
   * @param {Number} maxHeight - max height of the textarea in px
   */
  resizeTextarea(target, maxHeight) {
    target.style.height = 'inherit';
    const computed = window.getComputedStyle(target);
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      target.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    target.style.height = `${height}px`;
    target.style.height = `${Math.min(height, maxHeight)}px`;
  },
  /**
   * Opens the given URL in a new window
   * @param {string} url - URL to open
   */
  openInNewWindow(url) {
    window.open(url, '_blank', 'noreferrer');
  },
};

export default utilService;

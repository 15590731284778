import React, { Component } from 'react';
import * as s from './Pagenav.module.css';
import { pageDataStore, eventsStore, globalModalStore } from '../../stores';
import { observer } from 'mobx-react';
import { Colors, Text, Button, Icon, HSpacer } from '@mqd/volt-base';

class Pagenav extends Component {
  constructor(props) {
    super(props);
    this.headingPositions = []; // Array of content heading Y positions in increasing order
    this.state = {
      activeAnchor: null,
    };
  }

  render() {
    return (
      <>
        <nav className={s.pagenav} data-testid="pagenav">
          {this.renderList()}
          <Button
            type="outline"
            onClick={() => globalModalStore.setModal('feedback')}
            testId="pagenav-give-feedback-button"
          >
            <span className={s.feedbackButton}>
              <Icon type="feedback16" factor={1} overrideColor={Colors.blackLighter3} />
              <HSpacer factor={1} />
              Give feedback
            </span>
          </Button>
        </nav>
      </>
    );
  }

  componentDidMount() {
    this.prepScrollEvents();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setActiveAnchor);
    window.removeEventListener('resize', this.getHeadingPositions);
  }

  prepScrollEvents() {
    // setting up anchors
    const setupAnchors = () => {
      this.getHeadingPositions();
      window.addEventListener('scroll', this.setActiveAnchor);
    };

    // when page is loaded, call method
    eventsStore.onPageLoad(setupAnchors);

    // if page data has changed, get new heading positions
    pageDataStore.onPageChange(() =>
      // delay in order to call method after other onLoad events
      setTimeout(() => eventsStore.onPageLoad(this.getHeadingPositions), 0)
    );

    // get new heading positions upon resize of screen
    window.addEventListener('resize', this.getHeadingPositions);
  }

  setActiveAnchor = () => {
    const scroll = window.scrollY;
    // get list of headings
    const { h2s: headings } = pageDataStore.pageInfo;
    // get heading positions determined previously by the 'getHeadingPositions' method
    // make a copy and reverse it so the lowest header is at the front
    const headingPositions = this.headingPositions.slice().reverse();
    let activeIndex = headingPositions.findIndex(
      // retrieve the first header that has position higher larger than the current window scroll
      (position, index) => scroll >= position - 30 // account for top padding
    );
    if (!~activeIndex) {
      activeIndex = null;
    } else {
      activeIndex = this.headingPositions.length - activeIndex - 1;
    }
    this.setState({
      activeAnchor: headings[activeIndex] && headings[activeIndex].anchor,
    });
  };

  getHeadingPositions = () => {
    const { h2s: headings } = pageDataStore.pageInfo;
    const headerOffset = document.getElementsByTagName('header')[0].offsetHeight;
    this.headingPositions = headings.map((heading) => {
      const elem = document.getElementById(heading.anchor);
      return !!elem && elem.offsetTop + headerOffset;
    });
  };

  shouldScrollToLastAnchor() {
    // If the window scroll is at the very bottom, the last anchor will be set to active
    const { h2s: headings } = pageDataStore.pageInfo;
    if (!headings.length) return;
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      this.setState({ activeAnchor: headings[headings.length - 1].anchor });
      return true; // returns true if scrolled to bottom
    }
  }

  renderList() {
    const { h2s: headings } = pageDataStore.pageInfo;
    if (headings.length === 0) {
      return null;
    }
    return (
      <ul className={s.list}>
        <li className={`${s.listItem} ${s.header}`}>{this.renderListHeader()}</li>
        {this.renderListItems()}
      </ul>
    );
  }

  renderListItems() {
    const { h2s: headings } = pageDataStore.pageInfo;
    const { activeAnchor } = this.state;
    return headings.map(({ anchor, httpMethod, title }) => (
      <li key={anchor} className={s.listItem} data-testid="pagenav-item">
        <a href={`#${anchor}`} className={s.listItemLink} data-testid="pagenav-item-link">
          <Text
            type="body-sm"
            inline={true}
            className={activeAnchor === anchor ? s.active : ''}
            testId="pagenav-item-link-text"
          >
            {title}
          </Text>
          <Text
            type="footnote"
            inline={true}
            className={s.listItemMethod}
            testId="pagenav-http-method"
          >
            {httpMethod}
          </Text>
        </a>
      </li>
    ));
  }

  renderListHeader() {
    const { urlCollection } = pageDataStore.pageInfo;
    const { pagenavTitle } = pageDataStore.siteMapInfo.find((m) => m.urlPath === urlCollection);
    return <Text type="h6">{pagenavTitle}</Text>;
  }
}

export default observer(Pagenav);

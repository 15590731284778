import { action, decorate, observable, computed, configure } from 'mobx';
import React from 'react';
import { ToastAlert } from '@mqd/volt-base';

configure({
  // All state that is observed somewhere needs to be changed through actions.
  enforceActions: true,
});

class GlobalToastStore {
  // observables
  toastInfo = null; // toast that should appear on screen

  // static variables
  toasts = {
    feedbackSubmitted: (dismissTime) => (
      <ToastAlert
        icon="success"
        action=""
        dismissTime={dismissTime}
        testId="feedback-submitted-toast"
      >
        Thanks for your feedback!
      </ToastAlert>
    ),
  };
  /**
   * Sets a toast alert on the bottom left corner of screen
   * @param {string} toastName - name of the toast to display
   * @param {number} dismissTime - how long before the toast is dismissed
   */
  setToast(toastName, dismissTime = 6) {
    const toast = this.toasts[toastName](dismissTime);
    if (toast) {
      this.toastInfo && this.dismissToast(this.toastInfo.name); // dismiss existing toast if present
      this.toastInfo = {
        name: toastName,
        component: toast,
      }; // set the new toast
      setTimeout(() => this.dismissToast(toastName), dismissTime * 1000); // dismiss new toast after x seconds
    } else {
      this.toastInfo = null;
    }
  }

  dismissToast(toastName) {
    if (this.toastInfo.name === toastName) {
      this.toastInfo = null;
    }
  }

  get currentToast() {
    return this.toastInfo && this.toastInfo.component;
  }
}

decorate(GlobalToastStore, {
  // values
  toastInfo: observable,

  //actions
  setToast: action.bound,
  dismissToast: action.bound,

  // computed
  currentToast: computed,
});

const globalToastStore = new GlobalToastStore();
export default globalToastStore;

import React, { Component } from 'react';
import { Icon, Tooltip, Colors } from '@mqd/volt-base';
import * as s from './HeadingLink.module.css';
import { observer } from 'mobx-react';
import { utilService } from '../../services';
import { eventsStore } from '../../stores';

class HeadingLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTipContent: 'Copy section link',
    };
  }

  handleClick = () => {
    const url = [window.location.origin, window.location.pathname, '#', this.props.hash].join('');
    utilService.copyToClipboard(url);
    this.setState({ toolTipContent: 'Copied!' });
  };

  render() {
    const mobile = eventsStore.mobileMode;

    // temporary until we find the time to have the Tooltip ghost be in the exact position that the Tooltip will appear, and thus never be cutoff by the window edge.
    if (mobile) return null;

    return (
      <div
        className={s.headingLink}
        onClick={this.handleClick}
        onMouseLeave={() => this.setState({ toolTipContent: 'Copy section link' })}
      >
        <Tooltip
          pointerEvents={true}
          clickOnly={!!mobile}
          content={mobile ? 'Copied!' : this.state.toolTipContent}
          direction="top"
        >
          <Icon
            type="hyperlink18"
            overrideColor={Colors.blackLighter5}
            hasTooltip={true}
            factor={1}
          />
        </Tooltip>
      </div>
    );
  }
}

export default observer(HeadingLink);

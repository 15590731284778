// extracted by mini-css-extract-plugin
export var botMessage = "Chatbot-module--botMessage--a6ee0";
export var botMessageContent = "Chatbot-module--botMessageContent--89b7e";
export var botMessageFooter = "Chatbot-module--botMessageFooter--372ab";
export var bounceInUp = "Chatbot-module--bounceInUp--46f0d";
export var buttonFadeIn = "Chatbot-module--buttonFadeIn--253d9";
export var buttonFadeOut = "Chatbot-module--buttonFadeOut--8733f";
export var chatbotBody = "Chatbot-module--chatbotBody--f2734";
export var chatbotButton = "Chatbot-module--chatbotButton--b1887";
export var copyFill = "Chatbot-module--copyFill--d9988";
export var copyIcon = "Chatbot-module--copyIcon--60842";
export var disabled = "Chatbot-module--disabled--87f0c";
export var feedbackButton = "Chatbot-module--feedbackButton--1e994";
export var footer = "Chatbot-module--footer--4251a";
export var form = "Chatbot-module--form--2d7f7";
export var hasCopyButton = "Chatbot-module--hasCopyButton--ec464";
export var header = "Chatbot-module--header--4ac28";
export var helpTooltip = "Chatbot-module--helpTooltip--f2aa9";
export var hideScrollButton = "Chatbot-module--hideScrollButton--9b4cd";
export var iconContainer = "Chatbot-module--iconContainer--0b51a";
export var icons = "Chatbot-module--icons--02304";
export var isCopied = "Chatbot-module--isCopied--64be6";
export var scrollButton = "Chatbot-module--scrollButton--cc634";
export var scrollButtonContainer = "Chatbot-module--scrollButtonContainer--50399";
export var sendIcon = "Chatbot-module--sendIcon--09851";
export var showScrollButton = "Chatbot-module--showScrollButton--203eb";
export var thumb_down_rotate = "Chatbot-module--thumb_down_rotate--14e8e";
export var thumb_up_fill = "Chatbot-module--thumb_up_fill--279f2";
export var thumb_up_opacity = "Chatbot-module--thumb_up_opacity--d9be1";
export var thumb_up_outline = "Chatbot-module--thumb_up_outline--2d5b1";
export var thumb_up_rotate = "Chatbot-module--thumb_up_rotate--ad26a";
export var thumbsDown = "Chatbot-module--thumbsDown--17a0f";
export var thumbsUp = "Chatbot-module--thumbsUp--f461b";
export var title = "Chatbot-module--title--508dd";
export var url = "Chatbot-module--url--09e90";
export var urlList = "Chatbot-module--urlList--a35cc";
export var userMessage = "Chatbot-module--userMessage--a99d2";